import { createSelector } from 'reselect';
import { pick, reduce, sortBy } from 'underscore';
import { ModelsState, RootState } from 'redux/schemas';
import moment from 'moment';
import {
  Mentionables,
  GallerySubmissionsState,
  MySubmissionsState,
  ScenariosListState,
  SubmissionsListstate,
  UserSubmissionsState,
} from 'redux/schemas/app/video-practice';
import {
  PracticeRoomTab,
  PracticeSubmissionComment,
  VideoPracticeScenario,
  VideoPracticeSubmission,
  VideoPracticeSubmissionsNormalized,
} from 'redux/schemas/models/video-practice';
import { SliderAction } from 'practice_room/components/shared/user-submission-slider';
import { SubmissionPreview } from 'practice_room/components/submission-overview/submission-overview';
import { Badge } from 'shared/components/nv-seekbar-badges';
import { ComponentType, NLectureComponent } from 'redux/schemas/models/lecture-component';
import { VideoPracticeFeedbackActivitiesNormalized, VideoPracticeFeedbackActivity } from 'redux/schemas/models/video-practice-feedback';
import { VideoPracticeSkillsRating, VideoPracticeSkillsRatingNormalized } from 'redux/schemas/models/video-practice-skills-feedback';
import { ExerciseSkillsRatingActivity } from 'redux/schemas/models/exercise-skills-rating';
import { feedbackMapping } from './video-practice-feedback';
import { allTags } from './skills-feedback';

type GetSubmissionsForActivityProps = {
  activityId: number;
  userId?: number;
};

type GetSortedIdsProps = {
  selectedTab: PracticeRoomTab;
  isFilterApplied?: boolean;
};

const submissionIds = (state: RootState, props: GetSubmissionsForActivityProps) => state.models.practiceActivities[props.activityId].submissionIds;
const practiceSubmissions = (state: RootState) => state.models.practiceSubmissions;


export const getSubmissionsForActivity = createSelector<RootState, GetSubmissionsForActivityProps, number[], VideoPracticeSubmissionsNormalized, number, VideoPracticeSubmission[]>(
  [
    submissionIds,
    practiceSubmissions,
    (_, props) => props.userId,
  ],
  (ids, submissions, userId) => {
    // array of submissions based on submissionIds
    const submissionArray = (ids || []).map(id => submissions[id]);

    // array of submissions filtered to remove current user
    return submissionArray.filter(submission => submission.user.id !== userId);
  },
);

export const getScenario = (state: RootState, scenarioId: number): VideoPracticeScenario => state.models.practiceScenarios[scenarioId];

export const getScenarioListDetails = (state: RootState, catalogId?: string): ScenariosListState => {
  if (!catalogId) {
    return {
      ...state.app.practiceRoom.scenariosList,
      sortedScenarioIds: state.app.practiceRoom.scenariosList.scenarioIds,
    };
  }

  const sortedScenarioIds = [...state.app.practiceRoom.scenariosList.scenarioIds]
    .sort((scenarioIdA, scenarioIdB) => {
      const isCurrentCourseA = isCurrentCourseScenario(state, scenarioIdA, catalogId);
      const isCurrentCourseB = isCurrentCourseScenario(state, scenarioIdB, catalogId);

      if (isCurrentCourseA === isCurrentCourseB) {
        return 0;
      }

      return isCurrentCourseA ? -1 : 1;
    });

  return {
    ...state.app.practiceRoom.scenariosList,
    sortedScenarioIds,
  };
};

export const isCurrentCourseScenario = (state: RootState, scenarioId: number, catalogId: string): boolean => (
  state.models.practiceScenarios[scenarioId]?.coursesUsedInCatalogIds?.includes(catalogId)
);

export const getSubmission = (state: RootState, submissionId: number): VideoPracticeSubmission => state.models.practiceSubmissions[submissionId];

export const getGallerySubmissions = (state: RootState): GallerySubmissionsState => state.app.practiceRoom.gallerySubmissions[state.app.practiceRoom.params?.scenarioId];
export const getFeaturedSubmissions = (state: RootState): SubmissionsListstate => state.app.practiceRoom.featuredSubmissions[state.app.practiceRoom.params?.scenarioId];
export const getMySubmissions = (state: RootState): MySubmissionsState => state.app.practiceRoom.mySubmissions[state.app.practiceRoom.params?.scenarioId];

const submissionIdsForTab = (state: RootState, selectedTab: PracticeRoomTab) => {
  if (selectedTab === PracticeRoomTab.MY_PRACTICE) {
    return state.app.practiceRoom.mySubmissions[state.app.practiceRoom.params?.scenarioId]?.submissionIds ?? [];
  }
  if (selectedTab === PracticeRoomTab.GALLERY) {
    return state.app.practiceRoom.gallerySubmissions[state.app.practiceRoom.params?.scenarioId]?.submissionIds ?? [];
  }
  if (selectedTab === PracticeRoomTab.FEATURED) {
    return state.app.practiceRoom.featuredSubmissions[state.app.practiceRoom.params?.scenarioId]?.submissionIds ?? [];
  }
  return [];
};

export const getSubmissionIds = createSelector<RootState, GetSortedIdsProps, VideoPracticeSubmissionsNormalized, number[], boolean, number[]>(
  [
    practiceSubmissions,
    (state, props) => submissionIdsForTab(state, props.selectedTab),
    (_, props) => props.isFilterApplied,
  ], (submissions, ids, isFilterApplied = false) => {
    if (isFilterApplied) {
      return ids;
    }
    const sortedSubmissionIds = ids?.length
      ? [...ids].sort((idA, idB) => {
        const submissionA = submissions[idA];
        const submissionB = submissions[idB];
        if (moment(submissionA?.completedAt).isAfter(submissionB?.completedAt)) {
          return -1;
        } if (moment(submissionA?.completedAt).isBefore(submissionB?.completedAt)) {
          return 1;
        }
        return 0;
      }) : [];
    return sortedSubmissionIds;
  },
);

export const getBadges = (state: RootState, submissionId: number) => {
  const submission = getSubmission(state, submissionId);

  if (!submission.videoFile?.length) {
    // If the submission video doesn't have a length, there is no point
    // setting up the cue points
    return [];
  }

  const badgesObject = reduce(submission.submissionComments, (acc: { [Key: number]: Badge }, commentId) => {
    const comment: PracticeSubmissionComment = state.models.comments[commentId];

    // Either its a timestamp in the comment, or its the last point
    const hasTimestamp = comment.videoTimestamp && comment.videoTimestamp > 0;

    const key = hasTimestamp
      ? comment.videoTimestamp
      : 0;

    acc[key] = {
      ...acc[key] ?? {},
      point: hasTimestamp
        ? Math.ceil((comment.videoTimestamp / submission.videoFile.length) * 100)
        : 0,
      meta: {
        ...acc[key]?.meta ?? {},
        time: key,
        user: comment.user,
        isHighlighted: state.app.practiceRoom.filteredComments
          && state.app.practiceRoom.filteredComments[submissionId]?.includes(commentId),
        extras: {
          commentIds: [
            ...acc[key]?.meta?.extras?.commentIds ?? [],
            commentId,
          ],
        },
      },
    };

    if (!hasTimestamp) {
      if (acc[key].meta.count) {
        acc[key].meta.count += 1;
      } else {
        acc[key].meta.count = 1;
      }
    }
    return acc;
  }, {});

  return Object.values(badgesObject);
};

export const getSubmissionPreviews = (state: RootState, submissionId: number): SubmissionPreview => {
  const submissions: { [key in SliderAction]: VideoPracticeSubmission } = { next: null, prev: null };
  const tabKey = state.app.practiceRoom.params.selectedTab === PracticeRoomTab.GALLERY ? 'gallerySubmissions' : 'featuredSubmissions';
  const submissionIndex = state.app.practiceRoom[tabKey]?.[state.app.practiceRoom.params?.scenarioId]?.submissionIds?.findIndex(id => id === Number(submissionId));
  const nextSubmissionId = state.app.practiceRoom[tabKey]?.[state.app.practiceRoom.params?.scenarioId]?.submissionIds?.[submissionIndex + 1];
  const prevSubmissionId = state.app.practiceRoom[tabKey]?.[state.app.practiceRoom.params?.scenarioId]?.submissionIds?.[submissionIndex - 1];
  submissions[SliderAction.NEXT] = state.models.practiceSubmissions?.[nextSubmissionId] ?? null;
  submissions[SliderAction.PREV] = state.models.practiceSubmissions?.[prevSubmissionId] ?? null;
  return submissions;
};

export const getUserIdsForOverview = createSelector(
  [
    (state: RootState) => state.app.practiceRoom.params,
    (state: RootState) => state.app.practiceRoom.gallerySubmissions,
    (state: RootState) => state.app.practiceRoom.featuredSubmissions,
  ],
  (params, gallerySubmissions, featuredSubmissions) => {
    if (!params?.galleryMode) {
      return params?.userId ? [params.userId] : [];
    }
    if (params?.selectedTab === PracticeRoomTab.GALLERY) {
      return gallerySubmissions[params.scenarioId]?.userIds;
    }
    if (params?.selectedTab === PracticeRoomTab.FEATURED) {
      return featuredSubmissions[params.scenarioId]?.userIds;
    }

    return params?.userId ? [params.userId] : [];
  },
);

export const getUserSubmissions = (state: RootState, userId: number, scenarioId?: number): UserSubmissionsState => {
  const practiceRoomId = state.app.practiceRoom.params?.scenarioId ?? scenarioId;
  return state.app.practiceRoom.userSubmissions?.[practiceRoomId]?.[userId];
};

export const getSubmissionComments = (state: RootState, commentIds: number[]): PracticeSubmissionComment[] => (commentIds || []).map(id => state.models.comments[id]);

export const getMentionables = (state: RootState, submissionId: number): Mentionables => state.app.videoPracticeSubmissions[submissionId]?.mentionables;

const allUsers = (state: RootState) => state.models.users;
const mentionableUsers = (state: RootState, props: { submissionId: number }): number[] => state.app.videoPracticeSubmissions[props.submissionId]?.mentionables?.userIds ?? [];
export const getMentionableUsersForSubmission = createSelector([mentionableUsers, allUsers], (ids, users) => (ids || []).map(id => users[id]));

export const getSkillTags = createSelector([getScenario, allTags], (scenario, tags) => sortBy(scenario.skillTagIds, (id) => tags[id]?.name?.toLowerCase()).map(id => tags[id]));

export const getIsSkillsFeedbackActivity = createSelector<RootState, { lectureComponentId: number }, NLectureComponent, boolean>([
  (state, props) => state.models.lectureComponents[props.lectureComponentId],
], (component) => (
  component?.type === ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK
));

const modelObject = (state: RootState) => pick(state.models, 'exerciseSkillsRatingActivities', 'practiceFeedbackActivities');

type Props = {
  lectureComponentId: number,
};
type FeedbackModelOutput = {
  [key: string]: VideoPracticeFeedbackActivity | ExerciseSkillsRatingActivity
};

export const getPracticeFeedbackActivity = createSelector<RootState, Props, NLectureComponent, number, Pick<ModelsState, 'exerciseSkillsRatingActivities' | 'practiceFeedbackActivities'>, FeedbackModelOutput>([
  (state, props) => state.models.lectureComponents[props.lectureComponentId],
  (state) => state.app.videoPracticeFeedback?.practiceFeedbackCriteriaId,
  modelObject,
], (component, practiceFeedbackCriteriaId, models) => {
  const model = feedbackMapping[component?.type ?? ComponentType.VIDEO_PRACTICE_FEEDBACK];
  return models[model.activitiesKey]?.[practiceFeedbackCriteriaId];
});
