/* @ngInject */
export default function NvDiscussionLikes(
  $document,
  $timeout,
  CurrentUserManager,
  DiscussionsManager,
  PostModel,
) {
  return {
    restrict: 'A',
    scope: {
      discussionComponent: '=',
      topic: '=?',
      isLoading: '=?',
      context: '@',
      readonlyMode: '=?',
      pendoTag: '@?',
    },
    controller: function ctrl($scope, $element) {
'ngInject';
      const _this = this;

      this.likersLoaded = false;
      this.likersShown = false;

      this.toggleLikersPopop = toggleLikersPopop;
      this.toggleLike = toggleLike;
      this.likesAreDisabled = likesAreDisabled;

      this.currentUserManager = CurrentUserManager;

      // For readonly discussion posts and comments
      if (this.discussionComponent.numLikes >= 0) {
        this.discussionComponent.votesCount = this.discussionComponent.numLikes;
      }

      function toggleLike() {
        this.discussionComponent.liked = !this.discussionComponent.liked;

        if (this.discussionComponent.liked) {
          this.discussionComponent.like();
        } else {
          this.discussionComponent.unlike();
        }
      }


      function likesAreDisabled() {
        return (_this.discussionComponent?.belongsToCurrentUser && _this.discussionComponent.belongsToCurrentUser())
          || _this.isLoading
          || (_this.context === 'lectureVideo' && _this.discussionComponent instanceof PostModel)
          || (_this.context === 'lecture' && _this.discussionComponent instanceof PostModel)
          || (_this.context === 'report' && _this.discussionComponent.isCollaborator)
          || (_this.topic?.locked && !_this.currentUserManager.isAdminForCurrentCourse());
      }


      function toggleLikersPopop() {
        if (!this.likersLoaded) {
          this.discussionComponent.getLikersInfo().then(() => {
            _this.likersLoaded = true;
            _this.likersShown = true;
          });
        } else {
          this.likersShown = !this.likersShown;
        }
      }

      $document.on('click', outsideClickHandler);

      $scope.$on('$destroy', () => {
        $document.off('click', outsideClickHandler);
      });

      function outsideClickHandler(event) {
        $timeout(() => {
          const likersPopover = $('.social-row .popover');

          if (likersPopover.length && !likersPopover[0].contains(event.target) && !$element[0].contains(event.target) && _this.likersShown) {
            _this.likersShown = false;
            // likersPopover.scope().isOpen = false;
          }
        });
      }
    },
    controllerAs: 'vm',
    bindToController: true,
    templateUrl: 'discussions/templates/nv-discussion-likes.html',
  };
}
