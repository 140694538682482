import { useEffect } from 'react';
import { QrveyState } from 'redux/schemas/models/course';

// Provided by qrvey, this is how we reset the variables so we can switch dashboards and
// provide different CSS every time.
export const resetQrveyValues = () => {
  if (typeof window.customEUStyle !== 'undefined' && window.customEUStyle !== '') {
    window.customEUStyle = undefined;
  }
};

export const useQrveyInitializer = (
  data: QrveyState,
  isReady: boolean,
  isLoading: boolean,
  configKeyName: string,
  initializeQrvey: (data: QrveyState) => void,
) => {
  useEffect(() => {
    if (data && isReady && !isLoading) {
      initializeQrvey(data);
    }
  }, [data, isReady, isLoading]);

  useEffect(() => () => {
    // Cleanup function to reset QRvey-related values when component unmounts
    if (window[configKeyName]) {
      console.log(`Cleaning up existing config: ${configKeyName}`);
      delete window[configKeyName];
    }
    resetQrveyValues();
  }, [configKeyName]);
};
