// QrveyPanels.tsx
import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { halfSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { QrveyPanel } from 'redux/schemas/models/course';
import { extractTextFromHTML } from 'shared/utils';

type PanelProps = {
  panels: QrveyPanel[];
};

const styles = css`
  &.panel-container {
    flex-wrap: wrap;
    gap: ${halfSpacing}px;
    box-sizing: border-box;
  }

  .box {
    flex: 1 1 calc(50% - ${threeQuartersSpacing}px);
    height: 400px;
    box-sizing: border-box;
  }

  .box:last-child, .full-panel {
    flex: 1 1 100%;
    max-width: 100%;
    margin-right: 0;
  }
  .box:nth-of-type(2n) {
    margin-right: 0;
  }
  .an-panel {
    box-shadow: none !important;
  }
`;

const Panel = (props: { panelIndex: number }) => {
  useEffect(() => {
    // We cannot directly inject custom styles to shadow root DOM
    const checkForElement = () => {
      const panelElement = document.querySelector(`.an-panel-${props.panelIndex}`);

      if (panelElement?.shadowRoot) {
        const { shadowRoot } = panelElement;

        if (!shadowRoot.querySelector('style[data-injected]')) {
          const style = document.createElement('style');
          style.textContent = `
            .an-panel-header__button--filter {
              display:none !important;
            }
          `;
          style.setAttribute('data-injected', 'true');
          shadowRoot.appendChild(style);
        }
        return true;
      }
      return false;
    };

    // check every 500ms until the element is found
    const intervalId = setInterval(() => {
      if (checkForElement()) {
        clearInterval(intervalId);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [props.panelIndex]);

  return (
    <an-panel
      config={`anPanelConfig${props.panelIndex}`}
      class={`an-panel-${props.panelIndex}`}
    />
  );
};

const QrveyPanels = ({ panels }: PanelProps) => (
  <div className='panel-container d-flex mt-6 w-100 pl-2' css={styles}>
    {panels?.map((panel, index) => (
      <div
        className={`d-flex flex-column justify-content-center box mb-5 ${panel?.isFullPanel ? 'full-panel' : ''}`}
        // eslint-disable-next-line react/no-array-index-key
        key={index}
      >
        {panel?.title && (
          <div
            className='panel-title ellipsis course-title-small mb-2 ml-1'
          >
            {extractTextFromHTML(panel.title)}
          </div>
        )}
        <Panel panelIndex={index} />
      </div>
    ))}
  </div>
);
export default QrveyPanels;
