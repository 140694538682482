import { css } from '@emotion/react';
import {
  halfSpacing, lectureBottomPanelZIndex,
  standardSpacing, resourceCenterPendoBadgeZIndex,
} from 'styles/global_defaults/scaffolding';
import React, { useContext } from 'react';
import ClickableContainer from 'components/clickable-container';
import { useSelector } from 'react-redux';
import t from 'react-translate';
import { setLeftPanelVisibility, setTimelineIsExpanded } from 'redux/actions/lecture-pages';
import { useAppDispatch } from 'redux/store';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import { gray2, hexToRgbaString, primary, warning } from 'styles/global_defaults/colors';
import { getCurrentCourse, getFlatCourseAliases } from 'redux/selectors/course';
import { screenSmMax, screenXsMax } from 'styles/global_defaults/media-queries';
import { useLecturePageFromParams, useLecturePageLink, useLecturePageParams } from 'lecture_pages/hooks/lecture-routing';
import BookmarkIcon from 'bookmarks/components/bookmark-icon';
import { createBookmark, highlightBookmark } from 'redux/actions/bookmarks';
import { BookmarkType } from 'redux/schemas/models/bookmark';
import { useCourseOutline } from 'redux/selectors/course-outline/use-course-outline';
import { getNextLecture, getPreviousLecture } from 'redux/selectors/lecture-page';
import { AngularServicesContext } from 'react-app';
import { useMediaQuery } from 'react-responsive';
import { setShowCourseCompletionStatusModal } from 'redux/actions/courses';
import { CourseCompletionStatusModal } from 'course_home/components/course-completion-status-modal';
import { getCurrentEnrollment } from 'redux/selectors/users';
import { setCourseModalViewed } from 'course_home/services/course-modal-viewed';
import { openConfirmationDialog } from 'redux/actions/confirmation-dialogs';
import { PreventNavigationReason } from 'redux/schemas/models/lecture-page';
import CourseOutlineProgressBar from './course-outline-progress-bar';
import { config } from '../../../config/pendo.config.json';

/**
 * Contains Back/Forward lecture buttons and a progress bar showing current progress
 * through the course and collection.
 */
export const LecturePageBottomPanel = () => {
  const enrollment = useSelector(getCurrentEnrollment);
  const catalogId = useSelector(state => state.app.currentCatalogId);
  const { nextCourseButtonEnabled } = useSelector(getCurrentCourse);
  const { preventPageNavigation, isLecturePageBottomReached } = useSelector(state => state.app.lecturePage);

  React.useEffect(() => {
    dispatch(setShowCourseCompletionStatusModal(false));
  }, []);

  const isNotDesktop = useMediaQuery({
    query: `(max-width: ${screenSmMax}px)`,
  });

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const styles = css`
    position: fixed;
    bottom: 0;
    /* Set the width based on the LHS nav view. The LHS only shows on desktop      .
     * screens. So reducing the LHS width from total width in desktop screens and
     * using full width on non desktop screens */
    width: calc(100vw - 60px);
    ${isNotDesktop && css`
      width: 100%;
    `}
    height: 60px;
    background-color: ${hexToRgbaString(gray2, 0.8)};
    z-index: ${lectureBottomPanelZIndex};

    display: flex;
    align-items: center;

    .previous, .next {
      position: absolute;
      border: 0px solid transparent;
    }

    .previous {
      right: 50%;
      border-right-width: 1px;
    }

    .next {
      left: 50%;
      border-left-width: 1px;
    }

    .toggle-button-container {
      user-select: false;

      :hover .outline-icon {
        background: ${gray2};
      }
    }
  `;

  const dispatch = useAppDispatch();
  const { isLeftPanelVisible } = useSelector(state => state.app.lecturePage);

  const { FlyoutModalManager } = useContext(AngularServicesContext);

  const toggleLeftPanelVisible = () => {
    const newVisibility = !isLeftPanelVisible;
    dispatch(setLeftPanelVisibility(newVisibility));

    if (!isNotDesktop) {
      dispatch(setTimelineIsExpanded(newVisibility));
    }
    // Added because for some reason is not being called automatically on colored dev servers, even if
    // it's running in local dev mode.
    window.dispatchEvent(new Event('resize'));
  };

  const params = useLecturePageParams();
  const lecturePage = useLecturePageFromParams();
  const lectureLink = useLecturePageLink();

  const courseAliases = useSelector(state => getFlatCourseAliases(state, params.catalogId));
  const isLectureLoaded = useSelector(state => lecturePage && state.app.lecturePage.isCurrentLectureLoaded);

  const courseOutline = useCourseOutline(params.catalogId);
  const previousLecture = getPreviousLecture(courseOutline, params.lecturePageId);
  const nextLecture = getNextLecture(courseOutline, params.lecturePageId);

  const navigateBack = isLectureLoaded && previousLecture && (() => {
    lectureLink({
      ...params,
      lecturePageId: previousLecture.id,
    });
  });

  // Navigate to the next lesson or show the course completion status modal
  const navigateForward = ((isLectureLoaded && nextLecture) || nextCourseButtonEnabled) && (() => {
    const showCourseCompletionStatusModal = () => {
      setCourseModalViewed(enrollment, catalogId);
      dispatch(setShowCourseCompletionStatusModal(true));
    };

    if (isLectureLoaded && nextLecture) {
      lectureLink({
        ...params,
        lecturePageId: nextLecture.id,
      });
    } else if (!nextLecture && nextCourseButtonEnabled) {
      // Check for unsaved changes in lecture page to display the overlay
      if (preventPageNavigation === PreventNavigationReason.SAVING_IN_PROGRESS) {
        dispatch(openConfirmationDialog({
          icon: 'warning',
          title: t.FORM.SAVING_CHANGES.NAVIGATE_AWAY(),
          cancelText: t.FORM.CANCEL(),
          confirmText: t.FORM.SAVING_CHANGES.OK(),
          onConfirm: showCourseCompletionStatusModal,
        }));
      } else {
        showCourseCompletionStatusModal();
      }
    }
  });

  const onCreateBookmark = () => {
    dispatch(createBookmark({
      type: BookmarkType.LECTURE_PAGE,
      catalogId: params.catalogId,
      componentId: params.lecturePageId,
    })).then(() => {
      FlyoutModalManager.openFlyout({
        controller: 'BookmarksFlyoutCtrl as vm',
        template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
        isNavigational: false,
      });
    });
  };

  const onHighlightBookmark = () => {
    dispatch(highlightBookmark({ id: lecturePage.bookmarkId }));
    FlyoutModalManager.openFlyout({
      controller: 'BookmarksFlyoutCtrl as vm',
      template: 'bookmarks/templates/bookmarks-flyout-react-app.html',
      isNavigational: false,
    });
  };

  // TODO: The prod app has an iframe covering this bottom panel. Why?
  return (
    <div css={styles}>
      <CourseOutlineProgressBar />
      <div className='d-flex justify-content-between w-100 align-items-center h-100'>
        <div className='toggle-button-container pl-4'>
          {/* TODO: Open/closed tooltip */}
          <NvTooltip text={isLeftPanelVisible ? t.LECTURE_PAGES.HIDE_OUTLINE(courseAliases) : t.LECTURE_PAGES.SHOW_OUTLINE(courseAliases)}>
            <ClickableContainer
              onClick={() => toggleLeftPanelVisible()}
              data-qa={config.pendo.lecturePage.openCloseIconOutline}
            >
              <div className='d-flex align-items-center'>
                <div
                  className={`outline-icon ${isLeftPanelVisible ? 'bg-primary' : ''} text-white rounded-circle p-2`}
                  data-qa={config.pendo.lecturePage[isLeftPanelVisible ? 'closeOutline' : 'openOutline']}
                >
                  <NvIcon size='medium' icon='course-menu' />
                </div>
                {!isHandheld && (
                  <div className='pl-2 text-white course-title-xs'>
                    {t.LECTURE_PAGES.OUTLINE()}
                  </div>
                )}
              </div>
            </ClickableContainer>
          </NvTooltip>
        </div>
        <div
          className='text-white h-100 w-100'
          data-qa={config.pendo.lecturePage.navigationArrows}
        >
          <NvTooltip text={previousLecture && t.LECTURE_PAGES.PREVIOUS_LECTURE(previousLecture.name)} enabled={!!previousLecture}>
            {/* This inner div must be present in order for React to insert these NvTooltips. I'm unsure
            as to why but it seems like an issue with React.Fragment */}
            <div className='previous' data-qa={config.pendo.lecturePage.previousPage}>
              <NavigationButton direction='previous' navigate={navigateBack} />
            </div>
          </NvTooltip>
          <NvTooltip text={nextLecture && t.LECTURE_PAGES.NEXT_LECTURE(nextLecture.name)} enabled={!!nextLecture}>
            <div className='next' data-qa={config.pendo.lecturePage.nextPage}>
              <NavigationButton direction='next' navigate={navigateForward} color={(isLecturePageBottomReached && isLectureLoaded) ? primary : gray2} />
            </div>
          </NvTooltip>
        </div>
        {params.catalogId && (
          <div className='pr-4'>
            <BookmarkIcon
              size='medium'
              bookmarked={!!lecturePage?.bookmarkId}
              onCreate={onCreateBookmark}
              onHighlight={onHighlightBookmark}
              createTooltip={t.LHS.BOOKMARKS.ADD_BOOKMARK.LESSON(courseAliases)}
              altText={t.LHS.BOOKMARKS.ADD_BOOKMARK.LESSON(courseAliases)}
              tooltipZIndex={resourceCenterPendoBadgeZIndex + 1}
              colors={{
                default: 'white',
                bookmarked: 'white',
              }}
            />
          </div>
        )}
      </div>
      <CourseCompletionStatusModal />
    </div>
  );
};

const NavigationButton = (props: { direction: 'previous' | 'next', navigate: () => void, color?: string }) => {
  const isNotDesktop = useMediaQuery({
    query: `(max-width: ${screenSmMax}px)`,
  });

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });
  const clickableContainerStyles = css`
  &:focus{
    outline: 2px solid ${warning} !important;
    outline-offset:0px !important;
  }`;
  const styles = css`
    ${isHandheld && css`
      width: 60px;
    `}
    height: 60px;
    display: flex;
    flex-direction: ${props.direction === 'previous' ? 'row' : 'row-reverse'};
    background-color: ${props.direction === 'previous' ? gray2 : props.color};
    justify-content: center;
    align-items: center;
    user-select: none;
    opacity: .8;
    border: hidden;
    border-radius: 0;
    outline: none;
    padding-left: ${standardSpacing}px;
    padding-right: ${standardSpacing}px;

    .icon {
      ${!isNotDesktop && css`
        ${props.direction === 'previous' && css`
          margin-right: ${halfSpacing}px;
        `};
        ${props.direction === 'next' && css`
          margin-left: ${halfSpacing}px;
        `};
      `};
    }
    :not(.disabled) {
      cursor: pointer;
    }

    &:hover:not(.disabled) {
      opacity: 1.0;
    }

    &.disabled {
      background-color: ${gray2};
      opacity: .5;

      ${isHandheld && css`
        opacity: .3;
      `}
    }
  `;

  return (
    <ClickableContainer onClick={() => props.navigate?.()} disabled={!props.navigate} css={clickableContainerStyles}>
      <div css={styles} className={`icon-container ${!props.navigate ? 'disabled' : ''}`}>
        <NvIcon size='small' icon={props.direction === 'previous' ? 'arrow-left' : 'arrow-right'} />
        {!isHandheld && (
          <div className='course-title-xs'>
            {(props.direction === 'previous' ? t.SHARED.PREVIOUS() : t.SHARED.NEXT()).toUpperCase()}
          </div>
        )}
      </div>
    </ClickableContainer>
  );
};


export default LecturePageBottomPanel;

