import { css } from '@emotion/react';
import React, { useState, useRef, useEffect, ReactNode } from 'react';
import t from 'react-translate';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { isEmpty } from 'underscore';

// Schemas
import { RootState } from 'redux/schemas';
import { PracticeAgain, PracticeRoomTab, SubmissionPrivacySetting, VideoPracticeScenario } from 'redux/schemas/models/video-practice';
import { UploadedFile } from 'redux/schemas/models/file-uploads';

// Actions
import { useAppDispatch } from 'redux/store';
import { clearNewNotification, getVideoPracticeSubmissions, setFilteredComments, setShowPracticeAgainModal } from 'redux/actions/video-practice';

// Selectors
import { getScenario } from 'redux/selectors/video-practice';

// Hooks
import { makeQueryString } from 'shared/hooks/use-query';
import useInfiniteScroll from 'shared/hooks/use-infinite-scroll';

// Services
import { isSafari, hasGetUserMedia, hasMediaRecorder } from 'recording/services/media-stream';

// Styles
import {
  doubleSpacing,
  halfSpacing,
  quarterSpacing,
  standardSpacing,
} from 'styles/global_defaults/scaffolding';
import {
  gray5,
  primary,
  black,
  hexToRgbaString,
} from 'styles/global_defaults/colors';
import {
  handheld,
  notDesktop,
  tablet,
  notHandheld,
  desktop,
  screenXsMax,
  screenSmMin,
} from 'styles/global_defaults/media-queries';

// Components
import { getCurrentInstitution } from 'redux/selectors/institutions';
import prodPathReplace from 'shared/prod-path-rewrite';
import NvResponsiveTabsRow from 'shared/components/nv-responsive-tabs-row';
import { NvResponsiveTabsDisplayType, NvTab } from 'shared/components/nv-responsive-tabs';
import NvIcon from 'shared/components/nv-icon';
import NvTooltip from 'shared/components/nv-tooltip';
import PracticeActivityModal from 'lecture_pages/components/video-practice/practice-activity-modal';
import PromptModal from 'practice_room/components/scenario-overview/prompt-modal';
import PracticeInstantInsightsModal from 'lecture_pages/components/video-practice/practice-instant-insights-modal';
import ValidationErrorMessage from 'shared/components/inputs/validation-error-message';
import NvPopover from 'shared/components/nv-popover';
import ClickableContainer from 'components/clickable-container';
import { config } from '../../../../config/pendo.config.json';

const textEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const hideItem = css`
  visibility: hidden;
  opacity: 0;
  height: 0;
`;

const showItem = css`
  visibility: visible;
  opacity: 1;
`;

const styles = (
  hasThumbnail: boolean,
  browserNotSupported: boolean,
  showtabs: boolean,
) => css`
  .header {
    width: 100%;
    max-height: 225px;
    position: absolute;
    top: 0;
    /* popover z-index = 5051, so to show header over the popover*/
    z-index: 5052;
    background: linear-gradient(315.42deg, rgba(220,253,253,0.3) 0%, rgba(247,251,232,0.3) 100%), white;
    border-bottom: solid 1px ${gray5};
    padding: ${doubleSpacing}px ${doubleSpacing}px 0;
    ${handheld(css`
      padding: ${standardSpacing}px ${standardSpacing}px 0;
    `)};

    /* To fix the style of Tab heading font */
    .page-title {
      font-family: "Open Sans Condensed", Helvetica, Arial, sans-serif;
      transition: all 0.4s;
    }

    .prompt {
      align-items: flex-start;
      margin-bottom: ${standardSpacing}px;

      .prompt-thumbnail {
        position: relative;
        cursor: pointer;
        margin-right: ${standardSpacing}px;
        ${handheld(css`
          margin-right: ${halfSpacing}px;
        `)};
        * {
          transition: all 0.4s;
        }
        img, .thumbnail-placeholder {
          max-width: unset;
          width: 160px;
          height: 100px;
          ${handheld(css`
            width: 80px;
            height: 50px;
          `)};
        }

        .thumbnail-placeholder {
          background: ${black};
        }

        .play-icon {
          position: absolute;
          width: ${doubleSpacing}px;
          height: ${doubleSpacing}px;
          left: ${halfSpacing}px;
          bottom: ${halfSpacing}px;
          border-radius: 50%;

          /* To show play icon rotating the dropdown-arrow icon 270 degree  */
          .icon {
            transform: rotate(270deg);
            color: #fff;
          }

          ${handheld(css`
            left: ${quarterSpacing}px;
            bottom: ${quarterSpacing}px;
            width: 25px;
            height: 25px;

            .icon {
              font-size: 12px;
            }
          `)};
        }
      }

      .prompt-text {
        /* 100%
         * - 160px of thumbnail image
         * - 20px of padding between thumbnail and text
         * - 60px virtual margin with practice again icon
         * - 100px Practice again icon width
         */
        width: calc(100% - ${hasThumbnail ? '180px' : '0px'} - 60px - 100px);
        ${tablet(css`
          /* 20px virtual margin with practice again icon */
          width: calc(100% - ${hasThumbnail ? '180px' : '0px'} - 20px - 100px);
        `)};
        ${handheld(css`
          /* 20px virtual margin with practice again icon and 80px thumbnail */
          width: calc(100% - ${hasThumbnail ? '80px' : '0px'} - 20px);
        `)};

        .title {
          white-space: nowrap;
          ${textEllipsis};
          transition: all 0.4s;
        }

        .description {
          cursor: pointer;
          ${textEllipsis};
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          ${notDesktop(css`
            ${hideItem};
          `)};
          ${showItem};
          &:hover {
            /*Overriding the color of text-body on hover*/
            color: ${primary} !important;
          }
        }

        .header-name {
          ${notDesktop(css`
            padding-bottom: ${quarterSpacing}px;
          `)};
          ${showItem};
          transition: all 0.4s;
        }
      }

      .practice-again {
        transition: all 0.4s;
        position: absolute;
        top: ${doubleSpacing}px;
        right: ${doubleSpacing}px;
        width: 100px;
        ${browserNotSupported ? 'opacity: 0.5;' : 'cursor: pointer;'}

        ${handheld(css`
          z-index: 1;
          top: 90px;
          width: 60px;
        `)};

        .practice-again-icon {
          img {
            border-radius: 50%;
            box-shadow:
            0 ${quarterSpacing}px ${standardSpacing}px 0 ${hexToRgbaString(primary, 0.3)},
            0 ${quarterSpacing}px ${standardSpacing}px 0 ${hexToRgbaString(black, 0.2)};
          }
        }

        .label {
          ${handheld(css`
            ${hideItem};
          `)};
          ${showItem};
          transition: all 0.4s;
        }
      }
    }

    .responsive-tab {
      ${showItem};
      transition: all 0.4s;
    }

    &.compact:not(:hover) {
      max-height: 80px;
      padding: ${standardSpacing}px ${doubleSpacing}px;
      ${handheld(css`
        padding: ${standardSpacing}px ${standardSpacing}px 0;
      `)};

      .prompt {
        align-items: center;
        margin-bottom: 0px;
        ${handheld(css`
          margin-bottom: ${standardSpacing}px;
        `)};

        .prompt-thumbnail {
          display: flex;
          align-items: center;
          justify-content:center;

          img, .thumbnail-placeholder {
            width: 64px;
            height: 40px;
          }

          .play-icon {
            left: unset;
            bottom: unset;
            width: 28px;
            height: 28px;

            .icon {
              font-size: 12px;
            }
          }
        }

        .prompt-text {
          /* 100%
          * - 80px of thumbnail image
          * - 20px of padding between thumbnail and text
          * - 60px virtual margin with practice again icon
          * - 80px Practice again icon width
          */
          width: calc(100% - ${hasThumbnail ? '100px' : '0px'} - 60px - 80px);
          ${tablet(css`
            /* 20px virtual margin with practice again icon */
            width: calc(100% - ${hasThumbnail ? '100px' : '0px'} - 20px - 80px);
          `)};
          ${handheld(css`
            /* 20px virtual margin with practice again icon and 64px thumbnail */
            width: calc(100% - ${hasThumbnail ? '64px' : '0px'} - 20px);
          `)};

          .title {
            ${handheld(css`
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
              -webkit-line-clamp: initial;
            `)};
          }

          .description {
            ${hideItem};
          }

          .header-name {
            ${hideItem};
          }
        }

        .practice-again {
          width: 80px;

          ${handheld(css`
            top: 60px;
            width: 60px;
          `)};

          .label {
            ${hideItem};
          }
        }
      }

      .responsive-tab {
        ${hideItem};
      }
    }

    &.compact:hover {
      /* In compact mode hover we are not setting the 'isCompactMode' false.
       * Instead we are adding the compact classes on &.compact:not(:hover).
       * The title class style is added by checking 'isCompactMode'.
       * We need to override the title class style here for showing the
       * title as per in the mock.
       * */
      ${notHandheld(css`
        .title {
          font-size: 36px;
          line-height: 50px;
        }
      `)};
      opacity: .95;
    }

    /* Adjust tab height in mobile */
    .nv-dropdown .mobile-dropdown {
      .tab.active {
        ${handheld(css`
          height: ${doubleSpacing}px;
          padding-top: 0px;
        `)};
      }
    }
  }

  /* The tab content need to be scrolled */
  .tab-content {
    position: relative;
    /* Tab height is view port height - top header height - bottom spacing */
    height: calc(100vh - 55px - 20px);
    /* The style of header is position: absolute, so for showing
     * the tab content correctly, adding the max height of the
     * header as padding.
     * */
    ${showtabs ? 'padding-top: 225px;' : 'padding-top: 165px;'}

    ${handheld(css`
      ${showtabs ? 'padding-top: 130px;' : 'padding-top: 90px;'}
    `)};
    /* Adding scroll to tab and hiding the same */
    overflow-y: auto;
    overflow-x: hidden;
  }

  .tab-content.compact {
    padding-top: 80px;
  }

  .gallery-card-tab-container {
    /* 240px(gallery card width) * 3 + 40(margin right) * 2 */
    max-width: 800px;
    ${tablet(css`
      /* 208px(gallery card width) * 3 + 20(margin right) * 2 */
      max-width: 664px;
    `)};
    ${handheld(css`
      /* 240px(gallery card width) * 1*/
      max-width: 240px;
      &.my-practice {
        max-width: 285px;
      }
    `)};
  }

  .gallery-card {
    ${desktop(css`
      margin-right: ${doubleSpacing}px;
      margin-bottom: ${doubleSpacing}px;
    `)};

    /* Tablet and partially small desktop */
    @media (min-width: ${screenSmMin}px) and (max-width: 1160px) {
      margin-right: ${standardSpacing}px;
      margin-bottom: ${standardSpacing}px;
      width: auto;
      flex: 0 0 30%;
    }

    ${handheld(css`
      margin-bottom: ${standardSpacing}px;
    `)};

    &:nth-of-type(3n) {
      margin-right: 0px !important;
    }
  }
`;

type PromptProps = {
  scenarioId: number,
  title: string,
  videoPrompt?: UploadedFile,
  textPrompt?: string,
  isCompactMode: boolean,
  browserNotSupported: boolean,
  showPracticeAgain?: boolean
  isPracticeRoom?: boolean
};

const Prompt = ({
  scenarioId,
  title,
  videoPrompt,
  textPrompt,
  isCompactMode,
  browserNotSupported,
  showPracticeAgain = true,
  isPracticeRoom,
}: PromptProps) => {
  const dispatch = useAppDispatch();
  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const toolTipEnabled = !browserNotSupported && !isHandheld && !isCompactMode;
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [submissionId, setSubmissionId] = useState<number>();
  const [loadingInsightModal, setLoadingInsightModal] = useState<boolean>(false);
  const [playVideo, setPlayVideo] = useState(false);

  const { speechToTextEnabled } = useSelector(getCurrentInstitution);
  const scenario = useSelector<RootState, VideoPracticeScenario>((state) => state.models.practiceScenarios[scenarioId]);
  const showPracticeAgainModal = useSelector(state => state.app.videoPractice?.showPracticeAgainModal);

  let titleClass: string;

  if (isHandheld) {
    titleClass = 'course-title-small';
  } else if (isCompactMode) {
    titleClass = 'course-title';
  } else {
    titleClass = 'course-title-lg';
  }

  const history = useHistory();
  const afterPracticeAgain = (submission) => {
    setSubmissionId(submission?.id);
    onPracticeAgain(false);
    // If speech to text is enabled and insight criterion is available then show the Instant Insight modal
    if (speechToTextEnabled && scenario.insightCriterion) {
      setLoadingInsightModal(true);
    } else if (submission?.id) {
      dispatch(getVideoPracticeSubmissions({
        scenarioId,
        tab: PracticeRoomTab.MY_PRACTICE,
      }));
      history.push(makeQueryString({
        submission: submission.id,
        selected: PracticeRoomTab.MY_PRACTICE,
      }));
    }
  };

  const onPracticeAgain = (showModal: boolean) => {
    dispatch(setShowPracticeAgainModal({ show: showModal }));
  };

  return (
    <div className='prompt d-flex'>
      {!isEmpty(videoPrompt) && (
        <ClickableContainer
          className='prompt-thumbnail'
          onClick={() => {
            setShowPromptModal(true);
            setPlayVideo(true);
          }}
        >
          {videoPrompt.thumbnailUrl ? (
            <img src={videoPrompt.thumbnailUrl} alt={title} />
          ) : (
            <div className='thumbnail-placeholder' />
          )}
          <div className='play-icon d-flex align-items-center justify-content-center bg-primary'>
            <NvIcon icon='dropdown-arrow' size='small' />
          </div>
        </ClickableContainer>
      )}
      <div className='prompt-text'>
        {isPracticeRoom && (
          <div className={`gray-2 header-name ${isHandheld ? 'course-title-xxs' : 'course-title-xs'}`}>
            {t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.PRACTICE_ROOM_FOR()}
          </div>
        )}
        <div className={`title ${titleClass}`}>
          {title}
        </div>
        {textPrompt && (
          <ClickableContainer
            className='description text-body'
            onClick={() => setShowPromptModal(true)}
          >
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: textPrompt }} />
          </ClickableContainer>
        )}
      </div>
      {showPracticeAgain && (
        <ClickableContainer
          className='practice-again'
          onClick={() => !browserNotSupported && onPracticeAgain(true)}
          pendo-tag-name={config.pendo.practice.practiceAgain}
          aria-label={t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.PRACTICE_AGAIN()}
        >
          <NvPopover
            enabled={browserNotSupported}
            showOnHover
            placement='left'
            content={(
              <ValidationErrorMessage
                text={t.FILE_UPLOAD.CANNOT_RECORD.BROWSER_SUPPORT()}
              />
            )}
          >
            <div className='d-flex align-items-center flex-column'>
              <NvTooltip
                text={t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.PRACTICE_AGAIN_DESCRIPTION()}
                enabled={toolTipEnabled}
                placement='left'
              >
                <div className='practice-again-icon'>
                  <img
                    src={prodPathReplace('images/practice-button.png')}
                    alt={t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.PRACTICE_AGAIN()}
                    aria-hidden
                  />
                </div>
              </NvTooltip>
              <div className='label mt-2 text-primary'>
                {t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.PRACTICE_AGAIN()}
              </div>
            </div>
          </NvPopover>
        </ClickableContainer>
      )}
      <PromptModal
        title={title}
        videoPrompt={videoPrompt}
        textPrompt={textPrompt}
        show={showPromptModal}
        onClose={() => {
          setShowPromptModal(false);
          setPlayVideo(false);
        }}
        playVideo={playVideo}
      />
      {showPracticeAgainModal && (
        <PracticeActivityModal
          practiceType={PracticeAgain.PRACTICE_AGAIN}
          scenario={scenario}
          setShowModal={onPracticeAgain}
          afterSubmit={afterPracticeAgain}
        />
      )}
      {loadingInsightModal && (
        <PracticeInstantInsightsModal
          submissionId={submissionId}
          scenarioId={scenarioId}
          setShowInsightsModal={setLoadingInsightModal}
        />
      )}
    </div>
  );
};

type PracticeOverviewProps = {
  children: ReactNode,
  showHeaderTabs?: boolean,
  isPracticeFeedback?: boolean,
  isPracticeRoom?: boolean,
  scenarioId?: number
};
const VideoPracticeOverview = ({
  children,
  showHeaderTabs,
  isPracticeFeedback,
  isPracticeRoom,
  ...restProps
}: PracticeOverviewProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {
    scenarioId: scenarioParam,
    selectedTab,
    newNotification,
  } = useSelector((state) => state.app.practiceRoom.params);

  const scenarioId = scenarioParam ?? restProps.scenarioId;

  // Get scenario
  const scenario = useSelector((state) => getScenario(state, scenarioId));

  // Switch tab and set selected tab
  const switchTab = (tab: PracticeRoomTab) => {
    history.push(makeQueryString({
      selected: tab,
      galleryMode: true,
    }));

    setTimeout(() => {
      if (parentRef?.current) {
        parentRef?.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
    if (newNotification?.commentId) {
      dispatch(setFilteredComments({
        submissionId: newNotification?.submissionId,
        filteredComments: [],
      }));
      dispatch(clearNewNotification({}));
    }
  };

  // Setting compact mode
  const [isCompactMode, setCompactMode] = useState<boolean>(false);
  const parentRef = useRef<HTMLInputElement>(null);

  const isHandheld = useMediaQuery({
    query: `(max-width: ${screenXsMax}px)`,
  });

  const headerHeight = isPracticeFeedback ? 165 : 225;
  const handHeldHeaderHeight = isPracticeFeedback ? 90 : 130;

  // use header height based on the device.
  const nonCompactHeaderHeight = isHandheld ? handHeldHeaderHeight : headerHeight;

  // Checking if the scroll has reached the header height from the top
  const isScrolledFromHeader = useInfiniteScroll(
    parentRef?.current,
    isCompactMode ? 80 : nonCompactHeaderHeight,
    false,
  );

  useEffect(() => {
    if (parentRef?.current) {
      setCompactMode(isScrolledFromHeader);
    }
  }, [parentRef, isScrolledFromHeader, setCompactMode]);

  const tabs: NvTab[] = [
    {
      text: t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.TABS.MY_PRACTICE(),
      onClick: () => switchTab(PracticeRoomTab.MY_PRACTICE),
      pendoTagName: config.pendo.practice.viewMyPractice,
    }, {
      text: t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.TABS.FEATURED(),
      onClick: () => switchTab(PracticeRoomTab.FEATURED),
      pendoTagName: config.pendo.practice.viewHighlightedTab,
    },
  ];
  if (scenario?.isPracticeAdmin || scenario?.privacy === SubmissionPrivacySetting.SHARED) {
    tabs.push({
      text: t.PRACTICE_ROOM.PRACTICE_ROOM_MODAL.TABS.GALLERY(),
      onClick: () => switchTab(PracticeRoomTab.GALLERY),
      pendoTagName: config.pendo.practice.viewPracticeGallery,
    });
  }

  const browserNotSupported = isSafari || !hasGetUserMedia() || !hasMediaRecorder();

  return (
    <React.Fragment>
      {scenario && (
        <div css={styles(!isEmpty(scenario.videoPrompt), browserNotSupported, showHeaderTabs)}>
          <div className={`header ${isCompactMode ? 'compact' : ''}`}>
            <Prompt
              scenarioId={scenarioId}
              title={scenario.title}
              videoPrompt={scenario.videoPrompt}
              textPrompt={scenario.textPrompt}
              isCompactMode={isCompactMode}
              browserNotSupported={browserNotSupported}
              showPracticeAgain={!isPracticeFeedback}
              isPracticeRoom={isPracticeRoom}
            />
            {showHeaderTabs && (
              <div className='responsive-tab'>
                {/* To avoid setting default tab , render only if selectedtab */}
                {selectedTab && (
                  <NvResponsiveTabsRow
                    defaultTabs={tabs}
                    tabType={NvResponsiveTabsDisplayType.TEXT_ONLY}
                    revertActiveTab={selectedTab && Object.values(PracticeRoomTab).indexOf(selectedTab)}
                  />
                )}
              </div>
            )}
          </div>
          <div
            className={`tab-content ${isCompactMode ? 'compact' : ''}`}
            ref={parentRef}
          >
            {children}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default VideoPracticeOverview;
