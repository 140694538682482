import { css } from '@emotion/react';

export const quarterSpacing = 5;
export const halfSpacing = 10;
export const threeQuartersSpacing = 15;
export const standardSpacing = 20;
export const largeSpacing = 30;
export const doubleSpacing = 40;
export const extraLargeSpacing = 50;
export const tripleSpacing = 60;

export const reorderToolsPanelHeight = 50;

export const modalHeaderHeight = 60;
export const cardIconSize = 80;
export const cardSize = 280;
export const cardOverlaySize = 100;
export const shortBar = 120;
export const longBar = 200;
export const journeyIconSize = 40;
export const journeyCardWidth = 300;
export const flyoutWidth = 360;
export const flyoutHeight = 60;

export const flashMessageOuterMarginSize = 4 * largeSpacing;
export const flashMessageMaxWidth = 924;

// These should match the z-index master list in Bootstrap 4's _variables.scss
export const lectureTopGutterZIndex = 2;
export const lectureLeftPanelZIndex = lectureTopGutterZIndex + 1;
export const lectureBottomPanelZIndex = 1010;
// The l2-header is set to 1025 via a rule on a #top-nav selector
export const lectureTopPanelZIndex = 1035;
// Note that this is set to 5050 in the Angular.js app in some places, like the historical lecture component
// edit modals
export const modalOverlayZIndex = 1050;
export const popoverZIndex = 1060;
export const tooltipZIndex = 5051;
export const dropdownZIndex = 1000;
export const alertMessageZIndex = 8000;

export const standardModalWidth = 960;
export const topHeaderHeight = 55;
export const institutionBrandBorderWidth = 5;
export const discussionsModalMaxWidth = 720;

export const resourceCenterPendoBadgeZIndex = 19000;

// Custom component z indexes. Should be based on the above
export const datePickerZIndex = popoverZIndex + 1;

export const animationSize = 230;

// TODO: Is there a better place to put this general utility function?
/** Create a CSS properties object for use in a 'style' attribute that calculates the correct gridColumn and gridRow styles.
 * Please use this instead of setting these manually as it handles correct formatting for the IE11 prefixed version of these properties. */
export const createGridStyles = (column: number, row?: number, columnEnd?: number, rowEnd?: number) => {
  const styles: any = {
    gridColumn: columnEnd ? `${column} / ${columnEnd}` : column.toString(),
    msGridColumn: column.toString(),
  };

  if (columnEnd) {
    styles.msGridColumnSpan = columnEnd - column;
  }

  if (row) {
    styles.gridRow = rowEnd ? `${row} / ${rowEnd}` : row.toString();
    styles.msGridRow = row.toString();
  }

  if (rowEnd) {
    styles.msGridRowSpan = rowEnd - row;
  }

  return styles;
};

export const dropdownBottomPadding = (height) => css`
  &:after {
    position: absolute;
    content: "";
    bottom: -${height}px;
    height: ${height}px;
    width: 1px;
  }
`;

