import { createReducer } from '@reduxjs/toolkit';
import { fetchQuestionSet, getAnalyticsToken } from 'redux/actions/analytics';
import { QrveyState } from 'redux/schemas/models/course';
import { AnalyticsDashBoardType } from 'redux/schemas/app/analytics';
import { initialRootState } from '.';

export const initialQrveyState: QrveyState = {
  isLoading: false,
  type: AnalyticsDashBoardType.UNSET,
};
export default createReducer(initialRootState, builder => {
  builder

    .addCase(getAnalyticsToken.fulfilled, (state, action) => {
      state.app.qrveyConfig = { ...action.payload, type: action.meta.arg.type, isLoading: false };
    })
    .addCase(getAnalyticsToken.pending, (state, action) => {
      state.app.qrveyConfig.isLoading = true;
    })
    .addCase(fetchQuestionSet.fulfilled, (state, action) => {
      state.app.analytics.questionSets = action.payload;
      state.app.analytics.questionSetsLoaded = true;
    })
    .addCase(fetchQuestionSet.pending, (state, action) => {
      state.app.analytics.questionSetsLoaded = false;
    });
});
