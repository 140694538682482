import store from 'redux/store';
import { unsetCurrentCatalogId, unsetDiscoveryParams } from 'redux/actions/courses';
import { updateVisitedJourney } from 'redux/actions/learning-journeys';
import { unsetTranslationPreference } from 'redux/actions/users';
import { initialVisitedLearningJourney } from 'redux/reducers/learning-journeys';

/* @ngInject */
export default function Routes(
  $urlRouterProvider,
  $stateProvider,
) {
  /**
       * Gets a function that will redirect to a different angular ui router state if a condition fails.
       * @param {(CurrentPermissionsManager) => boolean} test Function to test for. Receives the CurrentPermissionsManager as its first argument
       * @param {string} redirectStateName The name of the state to redirect to
       */
  function redirectIfUnauthorized(test, redirectStateName, preventCourseLoad = false, loadJourney = false) {
    return ['$q', '$stateParams', 'CurrentUserManager', 'CurrentCourseManager', 'InstitutionsManager', 'CurrentPermissionsManager',
      function ($q, $stateParams, CurrentUserManager, CurrentCourseManager, InstitutionsManager, CurrentPermissionsManager) {
        const deferred = $q.defer();

        // Load the current user, course, and institution if they're not available. These set properties in CurrentPermissionsManager and
        // need to be available to do the permissions check
        const promises = [CurrentUserManager.requestCurrentUserPromise];

        if ($stateParams.catalogId && !CurrentCourseManager.course && !preventCourseLoad) {
          promises.push(CurrentCourseManager.requestCourse($stateParams.catalogId, true).catch(() => {
            // Adding redirect state if the course request failed when accessing content library routes
            if (redirectStateName === 'content-library-collections') {
              return deferred.reject({
                redirectTo: redirectStateName,
              });
            }

            return deferred.promise;
          }));
        }
        // for journey analytics dashboard we need to load the journey
        if ($stateParams.catalogId && loadJourney) {
          const requestJourneyPromise = CurrentCourseManager.requestCourse($stateParams.catalogId, true, null, true);
          // last param fetches the journey from journeys API and not from courses API
          promises.push(requestJourneyPromise);
        }

        if ($stateParams.institutionId && !InstitutionsManager.institution) {
          promises.push(InstitutionsManager.initialize($stateParams.institutionId));
        }

        // Resolve OK if the test passes, and redirect if it fails. See the `$rootScope.$on('$stateChangeError', ...` handler in app.js to understand
        // how redirection is handled.
        return $q.all(promises).then(() => {
          // Make sure an institution exists - attempting to nav into a page for an institution the user does not belong to will cause the institution to
          // be unset here
          if (InstitutionsManager.institution && test(CurrentPermissionsManager)) {
            deferred.resolve();
          } else {
            deferred.reject({
              redirectTo: redirectStateName,
              redirectParams: $stateParams,
            });
          }

          return deferred.promise;
        });
      }];
  }

  function checkOrgBelonging(
    $state,
    $location,
    authenticateUser,
    CurrentUserManager,
  ) {
    const userBelongsToCurrentSubdomainInstitution = CurrentUserManager.user.institutions.some(
      (institution) => institution.host === $location.host(),
    );

    if (!userBelongsToCurrentSubdomainInstitution
      && !CurrentUserManager.isNovoedAdmin()) {
      $state.go('dashboard');
    }
  }

  function requestInstitution(
    $stateParams,
    $state,
    InstitutionsManager,
    $location,
    $window,
    CurrentUserManager,
  ) {
    const institutionId = $stateParams?.institutionId || CurrentUserManager.currentInstitution.id;

    const initPromise = InstitutionsManager.initialize(institutionId);

    initPromise.then(
      (response) => {
        const correctDomainForInstitution = response.result.host;
        const currentUrlHost = $location.host();

        if (correctDomainForInstitution !== currentUrlHost) {
          const newUrl = `${$location.protocol()}://${correctDomainForInstitution}/#!${$location.path()}${$location.hash()}`;
          $window.location = newUrl;
        }
      },
      (result) => {
        if (result.status === 404) {
          $state.go('dashboard');
        }
      },
    );

    return initPromise;
  }

  let lastL1TrackerName;

  $urlRouterProvider.otherwise('/');

  $stateProvider
    /** Layouts * */
    .state('app-wrapper', {
      abstract: true,
      templateUrl: 'layouts/templates/nv-app-content.html',
      controller: 'MainGridCtrl as MainGridCtrl',
      url: '?{mobileapp:bool}&{webview_dismiss:bool}',
      resolve: {
        authenticateUser(
          StateManager,
          UserAuthentication,
          CurrentUserManager,
          $q,
          $state,
          amMoment,
          _,
        ) {
          if (!CurrentUserManager.user || !CurrentUserManager.hasLoggedIn()) {
            return UserAuthentication.verifyAuthenticated().then(
              () => {
                $('.loading-screen').hide();
                if (CurrentUserManager.user.timeZone) {
                  amMoment.changeTimezone(CurrentUserManager.user.timeZone);
                }
                if (StateManager && StateManager.lastStateAttempted.data.requiresLogin !== false && !CurrentUserManager.hasLoggedIn()) {
                  $state.go('users.authentications.sign-in');
                  return false;
                }

                return null;
              },
            );
          }
          return $q.when();
        },
      },
      params: {
        mobileapp: {
          value: false,
          squash: true,
        },
        webview_dismiss: {
          value: false,
          squash: true,
        },
      },
    })
    .state('root', {
      url: '/',
      data: {},
      parent: 'app-wrapper',
      resolve: {
        decideFirstState($state, authenticateUser, CurrentUserManager, CurrentPermissionsManager) {
          if (CurrentUserManager.hasLoggedIn()) {
            if (!CurrentUserManager.user.institution) {
              $state.go('change-institution', null, { replace: true });
            } else if (CurrentPermissionsManager.hasOrgAdminPermissions() || CurrentPermissionsManager.hasCourseManagerPermissions()) {
              $state.go(
                'institution-dashboard',
                { institutionId: CurrentUserManager.currentInstitution.id },
                { replace: true },
              );
            } else if (CurrentUserManager.isCourseAdminInAtLeastOneCourse()) {
              $state.go('course-admin-dashboard', null, { replace: true });
            } else if (CurrentPermissionsManager.isSupervisor()) {
              $state.go('course-supervisor-dashboard', null, { replace: true });
            } else if (CurrentPermissionsManager.hasOrgMentorPermissions()) {
              $state.go('mentor-dashboard', null, { replace: true });
            } else {
              $state.go('dashboard', null, { replace: true });
            }
          } else {
            $state.go('users.authentications.sign-in', null, { replace: true });
          }
        },
      },
    })
    // TODO: Check when this state is parent
    .state('user-grid-abstract', {
      abstract: true,
      sticky: true,
      parent: 'app-wrapper',
      views: {
        'page-content': {
          templateUrl: 'layouts/grid.html',
        },
      },
    })
    // 4th-level page in page hierarchy
    // https://projects.invisionapp.com/d/main#/console/6310900/144093318/preview
    .state('modal-page', {
      abstract: true,
      parent: 'app-wrapper',
      views: {
        'modal-page': {
          templateUrl: 'layouts/templates/nv-modal-page.html',
          controller: 'StickyHeaderFooterCtrl as stickyHeaderFooterCtrl',
        },
      },
      data: {
        level: 4,
        hasRhs: false,
        leftNavState: 'collapsed',
      },
    })
    .state('lightbox', {
      abstract: true,
      parent: 'mainGridContent',
      views: {
        main: {
          templateUrl: 'layouts/templates/nv-lightbox-main.html',
          controller: 'StickyHeaderFooterCtrl as stickyHeaderFooterCtrl',
        },
      },
      data: {
        level: 3,
        hasRhs: false,
        headerClass: 'dark-header',
      },
    })
    // This is the new flyout panel parent state. All children and sub-children will
    // Rendered in the new flyout panel. This is a sticky state, so we can move to this
    // State and go back without closing the background state.
    // Level: It should be 3.5 for all the flyout-panel states
    .state('flyout-panel', {
      abstract: true,
      parent: 'app-wrapper',
      sticky: true,
      views: {
        'flyout-panel': {
          templateUrl: 'layouts/templates/nv-flyout-panel.html',
          controller: 'FlyOutPanelController as FlyOutPanelController',
        },
      },
      data: {
      },
    })
    .state('institution-modal-page', {
      url: '/institutions/{institutionId:int}',
      abstract: true,
      parent: 'modal-page',
      resolve: {
        requestInstitution,
      },
    })
    // L4 outside of a course
    .state('non-course-lightbox', {
      abstract: true,
      parent: 'mainGridContent',
      views: {
        main: {
          templateUrl: 'layouts/templates/nv-lightbox-main.html',
          controller: 'StickyHeaderFooterCtrl as stickyHeaderFooterCtrl',
        },
      },
      data: {
        hasRhs: false,
        headerClass: 'dark-header',
      },
    })

    /** A lightbox state that should be used for all organization level lightboxes.
     * Unfortunately, the `non-course-lightbox` state above loads the in-course LHS experience
     * (with a course list), so we have to effectively 'override' that here */
    // TODO: Can we make this not be a duplicate of the above?
    .state('institution-lightbox', {
      abstract: true,
      parent: 'institution-abstract',
      views: {
        'main@mainGridContent': {
          templateUrl: 'layouts/templates/nv-lightbox-main.html',
          controller: 'StickyHeaderFooterCtrl as stickyHeaderFooterCtrl',
        },
      },
      data: {
        hasRhs: false,
        headerClass: 'dark-header',
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasCourseManagerPermissions(), 'dashboard'),
      },
    })

    /** Outside a Course * */
    .state('mainGridContent', {
      parent: 'user-grid-abstract',
      abstract: true,
      views: {
        'main-panel': {
          templateUrl: 'layouts/templates/main-content.html',
          controller: 'MainContentCtrl as vm',
        },
      },
    })
    .state('dashboard', {
      url: '/dashboard',
      parent: 'mainGridContent',
      views: {
        main: {
          templateUrl: 'dashboard/templates/dashboard-main.html',
          controller: 'DashboardCtrl as vm',
        },
        'top-header': {
          templateUrl: 'dashboard/templates/dashboard-header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'dashboard/templates/dashboard-subheader.html',
          controller: 'DashboardHeaderController as vm',
        },
      },
      resolve: {
        checkCourseVersions(
          CurrentUserManager,
          CurrentPermissionsManager,
          InstitutionsManager,
          _,
          $state,
          $location,
          $q,
          StateManager,
        ) {
          return CurrentUserManager.requestCurrentUserPromise.then(() => {
            // Extracting the state names
            const stateNames = {
              signIn: 'users.authentications.sign-in',
              dashboard: 'dashboard',
              changeInstitution: 'change-institution',
              institutionDashboard: 'institution-dashboard',
              mentorDashboard: 'mentor-dashboard',
              supervisorDashboard: 'course-supervisor-dashboard',
            };

            // Setting the user institutions data
            const userInstitutions = CurrentUserManager.user.institutions;

            // Setting the necessary data regarding the current institution
            const currentInstitution = {
              data: InstitutionsManager.institution,
              userIsOrgAdmin: CurrentPermissionsManager.hasCourseManagerPermissions(),
              userIsMentor: CurrentPermissionsManager.hasOrgMentorPermissions(),
              userHasEnrollments: CurrentUserManager.user?.enrollments?.length,
              hasDiscoveryEnabled: InstitutionsManager.institution?.discoveryEnabled,
            };

            // Helper function to handle the scenario when the user has only two institutions
            function handleSingleInstitutionCase(otherInstitutions) {
              // Setting the necessary data regarding the other institution
              const otherInstitution = {
                data: otherInstitutions[0],
                userIsAdmin: otherInstitutions[0]?.isInstitutionalAdmin,
                userHasEnrollments: CurrentUserManager.user?.institutionsWithEnrollment.find(institutions => institutions.id === otherInstitutions[0].id)?.id,
                hasDiscoveryEnabled: otherInstitutions[0]?.discoveryEnabled,
              };

              if (!otherInstitution.userIsAdmin) {
                // If the user has enrollments or is able to see the discovery section, go to the dashboard
                if (otherInstitution.userHasEnrollments || otherInstitution.hasDiscoveryEnabled) {
                  const dashboardUrl = $state.href('dashboard');
                  const redirectUrl = `${$location.protocol()}://${otherInstitution.data?.host}/${dashboardUrl}`;
                  return $q.reject({ redirectUrl });
                }
                // Otherwise, the user should see the helper message -> template: change-institution
                return $state.go(stateNames.changeInstitution);
              }

              // This is a hack to force the perms manager to check perms against the institution we're redirecting into
              CurrentPermissionsManager.setCurrentInstitution(otherInstitution.data);
              return $state.go(stateNames.institutionDashboard, {
                institutionId: otherInstitution.data?.id,
              });
            }

            // Main logic to determine if the user goes to an institution, to the list of institutions, or to the helper message
            if (!CurrentUserManager.hasLoggedIn()) {
              $state.go(stateNames.signIn);
            } else if (!currentInstitution.userHasEnrollments) {
              // Check if the user belongs to the current institution
              const userInCurrentInstitution = userInstitutions.find(institution => institution.id === currentInstitution.data?.id);

              // Verify if the user has additional institutions
              const otherInstitutions = userInstitutions.filter(institution => institution.id !== currentInstitution.data?.id);

              if (currentInstitution.hasDiscoveryEnabled && userInCurrentInstitution) {
                return $q.resolve();
              }

              if (currentInstitution.userIsOrgAdmin) {
                // Institutional admin has separate roles:
                // Org admin and Org admin course manager both these admin users has the access to the org admin dashboard
                $state.go(stateNames.institutionDashboard, {
                  institutionId: currentInstitution.data?.id,
                });
              } else if (currentInstitution.userIsMentor) {
                $state.go(stateNames.mentorDashboard);
              } else if (CurrentUserManager.isSupervisor()) {
                $state.go(stateNames.supervisorDashboard);
              } else if (otherInstitutions.length === 1) {
                return handleSingleInstitutionCase(otherInstitutions);
              } else if (otherInstitutions.length > 1) {
                // Otherwise, the user should see the institutions list -> template: change-institution
                $state.go(stateNames.changeInstitution);
              } else if (currentInstitution.data && StateManager.lastStateAttempted.name !== 'dashboard') {
                $state.go(stateNames.dashboard);
              } else {
                $state.go(stateNames.changeInstitution);
              }
            }

            return null;
          });
        },
      },
      data: {
        titleKey: 'COURSE_HOME.HEADER.DASHBOARD',
        headerClass: 'fixed-header dashboard-header org-admins-header',
        level: 1,
        hasRhs: false,
        hasInstitutionBackgroundHeader: true,
        rhsTitle: 'COURSE_HOME.HEADER.CHATTER',
        leftNavState: 'expanded',
        mainClass: 'learner-dashboard',
        expandedLHS: true,
        tabTitleOption: 'title_institution',
      },
    })
    .state('mentor-dashboard', {
      url: '/mentor-dashboard',
      parent: 'mainGridContent',
      views: {
        main: {
          templateUrl: 'org_mentor/templates/mentor-dashboard.html',
          controller: 'MentorDashboard as vm',
        },
        'top-header': {
          templateUrl: 'dashboard/templates/dashboard-header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'dashboard/templates/dashboard-subheader.html',
          controller: 'DashboardHeaderController as vm',
        },
      },
      onEnter(
        CurrentUserManager,
      ) {
        CurrentUserManager.isInMentorDashboard = true;
      },
      onExit(
        CurrentUserManager,
      ) {
        CurrentUserManager.isInMentorDashboard = false;
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasOrgMentorPermissions(), 'dashboard'),
      },
      data: {
        headerClass: 'fixed-header dashboard-header org-admins-header',
        level: 1,
        titleKey: 'COURSE_HOME.HEADER.DASHBOARD',
        hasRhs: false,
        hasInstitutionBackgroundHeader: true,
        leftNavState: 'expanded',
        mainClass: 'mentor-dashboard',
        expandedLHS: true,
        tabTitleOption: 'title_institution',
      },
    })
    .state('course-admin-dashboard', {
      url: '/course-admin-dashboard',
      parent: 'mainGridContent',
      views: {
        main: {
          templateUrl: 'course_admin_dashboard/templates/course-admin-dashboard.html',
          controller: 'CourseAdminDashboardCtrl as vm',
        },
        'top-header': {
          templateUrl: 'dashboard/templates/dashboard-header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'dashboard/templates/dashboard-subheader.html',
          controller: 'DashboardHeaderController as vm',
        },
      },
      data: {
        titleKey: 'COURSE_ADMIN_DASHBOARD.TITLE',
        headerClass: 'fixed-header dashboard-header',
        level: 1,
        hasRhs: false,
        hasInstitutionBackgroundHeader: true,
        leftNavState: 'expanded',
        mainClass: 'course-admin-dashboard',
        expandedLHS: true,
        tabTitleOption: 'title_institution',
      },
    })
    .state('course-supervisor-dashboard', {
      url: '/course-supervisor-dashboard?{tab:string}&{scenarioId:int}&{submissionId:int}',
      parent: 'mainGridContent',
      views: {
        main: {
          templateUrl: 'course_supervisor_dashboard/templates/course_supervisor_dashboard.html',
          controller: 'CourseSupervisorDashboardCtrl as vm',
        },
        'top-header': {
          templateUrl: 'dashboard/templates/dashboard-header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'dashboard/templates/dashboard-subheader.html',
          controller: 'DashboardHeaderController as vm',
        },
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager?.user?.mentoringInfo?.isMentor, 'dashboard'),
      },
      data: {
        headerClass: 'fixed-header dashboard-header',
        level: 1,
        hasRhs: false,
        hasInstitutionBackgroundHeader: true,
        leftNavState: 'expanded',
        mainClass: 'course-supervisor-dashboard',
        expandedLHS: true,
      },
    })
    .state('learning-catalog-modal', {
      parent: 'modal-page',
      url: '/learning-catalog',
      views: {
        'modal-page-header@modal-page': {
          templateUrl: 'dashboard/templates/learning-catalog-modal-header.html',
          controller: 'LearningCatalogCtrl as vm',
        },
        'modal-content@modal-page': {
          templateUrl: 'dashboard/templates/learning-catalog-modal-content.html',
          controller: 'LearningCatalogCtrl as vm',
        },
      },
      data: {
        level: 4,
        modalPage: {
          noScroll: true,
          noBorder: true,
          noPadding: true,
          noHeaderPadding: true,
        },
      },
      resolve: {
        requestInstitution,
      },
      onExit($timeout, $state) {
        // Closing the modal should update the dashboard page
        $timeout(() => {
          store.dispatch(unsetDiscoveryParams());
          $state.reload();
        });
      },
    })

    // User enrollment model in learner side
    .state('user-enrollment-details-modal', {
      parent: 'institution-modal-page',
      url: '/users/{userId:int}/enrollments?galleryMode&ltr',
      params: {
        galleryMode: null,
        ltr: null,
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'org_mentor/templates/learner-enrollment.html',
          controller: 'OrgUserDetailsCtrl as vm',
        },
      },
      data: {
        level: 4,
        mainClass: 'user-enrollment-details-modal',
        modalMaskClass: 'user-enrollment-details-modal-mask',
        titleKey: 'QUIZZES.ANSWERS',
        animateSlide: true,
        modalPage: {
          noBorder: true,
          noPadding: true,
        },
      },
      resolve: {
        checkPermission: redirectIfUnauthorized(
          (manager) => (manager.hasOrgAdminPermissions()),
          'non-admin-user-enrollment-details-modal',
        ),
      },
    })

    .state('non-admin-user-enrollment-details-modal', {
      parent: 'modal-page',
      url: '/users/{userId:int}/enrollments?galleryMode&ltr',
      params: {
        galleryMode: null,
        ltr: null,
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'org_mentor/templates/learner-enrollment.html',
          controller: 'OrgUserDetailsCtrl as vm',
        },
      },
      data: {
        level: 4,
        mainClass: 'user-enrollment-details-modal',
        modalMaskClass: 'user-enrollment-details-modal-mask',
        titleKey: 'QUIZZES.ANSWERS',
        animateSlide: true,
        modalPage: {
          noBorder: true,
          noPadding: true,
        },
      },
      resolve: {
        checkPermission: redirectIfUnauthorized(
          (manager) => (manager.hasOrgMentorPermissions()), 'dashboard',
        ),
      },
    })

    .state('submission-approval-dashboard', {
      url: '/submission-approval-dashboard',
      parent: 'mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'dashboard/templates/dashboard-header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'submission_approval_dashboard/templates/submission-approval-sort-filter-header.html',
          controller: 'SubmissionApprovalBaseCtrl as vm',
        },
        main: {
          templateUrl: 'submission_approval_dashboard/templates/main.html',
          controller: 'SubmissionApprovalDashboardCtrl as vm',
        },
      },
      resolve: {
      },
      data: {
        titleKey: 'COURSE_HOME.HEADER.SUBMISSION_APPROVAL',
        headerClass: 'light-header fixed-header institution-directory-page-header directory-page-header',
        level: 1,
        hasRhs: false,
        hasInstitutionBackgroundHeader: true,
        leftNavState: 'expanded',
        mainClass: 'submission-approval-dashboard directory-page-main',
        mainContent: {
          flex: true,
        },
        tabTitleOption: 'title_institution',
      },
    })

    .state('course-edit-basics', {
      parent: 'mainGridContent',
      url: '/institutions/{institutionId:int}/courses/{id:int}/edit_basics',
      params: {
        institutionId: null,
        id: null,
        prevStateData: null,
      },
      views: {
        'main@mainGridContent': {
          controller: 'EditCourseCtrl as vm',
          templateUrl: 'courses/templates/course-form.html',
        },
      },
      resolve: {
        requestCourse(
          CoursesManager,
          InstitutionsManager,
          $stateParams,
        ) {
          const institutionPromise = InstitutionsManager.initialize($stateParams.institutionId);
          const coursePromise = CoursesManager.requestCourseById($stateParams.id, { serializer: 'privileged' });
          return Promise.all([institutionPromise, coursePromise]);
        },
        checkPermission: redirectIfUnauthorized((manager) => manager.isConfigAndRegistrationRole(), 'dashboard'),
      },
      data: {
        headerClass: 'no-header',
        mainClass: 'course-form',
        mainPanelScrollable: true,
      },
    })

    .state('institution-abstract', {
      url: '/institutions/{institutionId:int}',
      parent: 'mainGridContent',
      abstract: true,
      resolve: {
        requestInstitution,
      },
    })
    .state('institution-dashboard-abstract', {
      parent: 'institution-abstract',
      data: {
        level: 1,
      },
    })
    // The angular.js wrapper around the React org admin dashboard. See app/institutions/components/router-root.tsx for specific React routes handled in this state
    .state('institution-dashboard', {
      parent: 'institution-dashboard-abstract',
      url: '/',
      params: {
        dashboardTab: null,
      },
      views: {
        'main@mainGridContent': {
          controller: 'InstitutionDashboardReactCtrl as vm',
          templateUrl: 'institutions/templates/institution-dashboard-react.html',
        },
        // This is hidden via the header class when on the courses list page, and is shown by dynamically
        // setting the data classes in license-dashboard.tsx
        'top-header@mainGridContent': {
          template: '<div nv-l1-course-header = ""></div>',
        },
      },
      data: {
        // Hide the #top-nav in main-content.jade
        headerClass: 'remove-top-header-view',
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasCourseManagerPermissions(), 'dashboard'),
      },
    })

    .state('institution-new-course', {
      parent: 'institution-dashboard-abstract',
      url: '/courses/new',
      params: {
        prevStateData: null,
      },
      views: {
        'main@mainGridContent': {
          controller: 'NewCourseCtrl as vm',
          templateUrl: 'courses/templates/course-form.html',
        },
      },
      data: {
        headerClass: 'no-header',
        mainClass: 'course-form',
        mainPanelScrollable: true,
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasCourseManagerPermissions(), 'dashboard'),
      },
    })
    .state('institution-edit-course', {
      parent: 'institution-dashboard-abstract',
      url: '/courses/{id:int}/edit',
      params: {
        prevStateData: null,
      },
      views: {
        'main@mainGridContent': {
          controller: 'EditCourseCtrl as vm',
          templateUrl: 'courses/templates/course-form.html',
        },
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasCourseManagerPermissions(), 'dashboard'),
        requestCourse(
          CoursesManager,
          $stateParams,
        ) {
          return CoursesManager.requestCourseById($stateParams.id, { serializer: 'privileged' });
        },
      },
      data: {
        headerClass: 'no-header',
        mainClass: 'course-form',
        mainPanelScrollable: true,
      },
    })
    .state('institution-dashboard-admin', {
      parent: 'institution-dashboard-abstract',
      abstract: true,
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasCourseManagerPermissions(), 'institution-dashboard'),
      },
    })
    .state('learning-journeys-abstract', {
      abstract: true,
      parent: 'institution-dashboard-abstract',
      url: '/learning-journeys',
      views: {
        'main@mainGridContent': {
          controller: 'LearningJourneysCtrl',
          templateUrl: 'learning_journeys/templates/learning_journeys.html',
        },
      },
      data: {
        headerClass: 'no-header',
      },
    })
    .state('learning-journeys', {
      parent: 'learning-journeys-abstract',
      url: '/',
    })
    .state('new-learning-journey', {
      parent: 'learning-journeys-abstract',
      url: '/new',
    })
    .state('learning-journey-basics', {
      parent: 'learning-journeys-abstract',
      url: '/:catalogId/edit-basics',
    })
    .state('edit-learning-journey', {
      parent: 'learning-journeys-abstract',
      url: '/:catalogId/edit?{isNewJourney:bool}',
      params: {
        isNewJourney: false,
      },
    })
    .state('learning-journey-analytics', {
      parent: 'learning-journeys-abstract',
      url: '/:catalogId/analytics',
      params: {
        from: '',
        catalogId: '',
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasJourneyAnalyticsAccess(),
          'dashboard', true, true),
      },
    })
    .state('learning-journey-communications', {
      parent: 'learning-journeys-abstract',
      url: '/:catalogId/journey_communications',
      params: {
        catalogId: '',
        titleLinkToJourneyHome: true,
      },
    })
    .state('institution-roles', {
      parent: 'institution-dashboard',
      /* Possible tab values are added for blocking unwanted route access */
      url: 'roles/{tab:all-users|org-admins|org-mentors|manage-course-roles}',
      params: {
        tab: 'all-users',
      },
      views: {
        'top-header@mainGridContent': {
          template: '<div nv-l1-course-header = ""></div>',
        },
      },
      data: {
        level: 1,
        titleKey: 'INSTITUTIONS.ROLES.MANAGING_ROLES',
        headerClass: 'light-header fixed-header institution-l1-header',
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasOrgAdminPermissions(), 'institution-dashboard'),
      },
    })
    .state('org-user-details-modal', {
      parent: 'institution-modal-page',
      url: '/license-enrollment/org-user-search/users/{userId:int}?galleryMode&ltr',
      params: {
        galleryMode: null,
        ltr: null,
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'org_mentor/templates/learner-enrollment.html',
          controller: 'OrgUserDetailsCtrl as vm',
        },
      },
      data: {
        level: 4,
        mainClass: 'org-user-details-modal',
        modalMaskClass: 'org-user-details-modal-mask',
        titleKey: 'QUIZZES.ANSWERS',
        animateSlide: true,
        modalPage: {
          noBorder: true,
          noPadding: true,
        },
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasOrgAdminPermissions(), 'institution-dashboard'),
      },
    })

    .state('institution-email-template', {
      parent: 'institution-dashboard-admin',
      url: '/email-template',
      views: {
        'main@mainGridContent': {
          controller: 'InstitutionEmailTemplateCtrl as vm',
          templateUrl: 'institutions/templates/email-template.html',
        },
        'top-header@mainGridContent': {
          template: '<div nv-l1-course-header = ""></div>',
        },
      },
      data: {
        titleKey: 'INSTITUTIONS.EMAIL_TEMPLATE.HEADER',
        headerClass: 'light-header fixed-header institution-l1-header',
        mainClass: 'email-template-main',
      },
    })
    .state('institution-edit-email-template', {
      parent: 'institution-dashboard-admin',
      url: '/edit-email-template',
      views: {
        'main@mainGridContent': {
          controller: 'InstitutionEditEmailTemplateCtrl as vm',
          templateUrl: 'institutions/templates/edit-email-template.html',
        },
        'top-header@mainGridContent': {
          template: '<div nv-l1-course-header = ""></div>',
        },
      },
      data: {
        titleKey: 'INSTITUTIONS.EMAIL_TEMPLATE.HEADER',
        headerClass: 'light-header fixed-header institution-l1-header',
        mainClass: 'email-template-main',
      },
    })

    .state('institution-settings', {
      parent: 'institution-dashboard-admin',
      url: '/settings?{zoom_account_oauth_success:bool}&{redirect:string}&{mailbox:bool}&{tab:string}',
      views: {
        'main@mainGridContent': {
          controller: 'InstitutionAdvancedSettingsCtrl as vm',
          templateUrl: 'institutions/templates/settings.html',
        },
        'top-header@mainGridContent': {
          template: '<div nv-l1-course-header = ""></div>',
        },
      },
      data: {
        titleKey: 'INSTITUTIONS.ADVANCED_SETTINGS.HEADER',
        headerClass: 'light-header fixed-header institution-l1-header',
        mainClass: 'advanced-settings-main',
      },
    })

    /** Users * */
    .state('users', {
      url: '/users',
      abstract: true,
      template: '<div ui-view></div>',
    })

    .state('change-institution', {
      url: '/change-institution?{mobileapp:bool}',
      templateUrl: 'users/templates/change-institution.html',
      controller: 'ChangeInstitutionCtrl as vm',
      params: {
        mobileapp: false,
      },
      data: {
        level: 1,
      },
      resolve: {
        checkHasInstitutions(
          UserAuthentication,
          CurrentUserManager,
          InteroperabilityRoutes,
          $window,
          _,
        ) {
          return UserAuthentication.verifyAuthenticated()
            .then(() => {
              $('.loading-screen').hide();
            });
        },
      },
    })

    /** User Authentication * */
    .state('users.authentications', {
      abstract: true,
      url: '?catalogId&mentor&plan&isJourney',
      controller: 'AuthenticationsCtrl as vm',
      templateUrl: 'users/templates/authentications.html',
      resolve: {
        requestCourse($stateParams, CurrentCourseManager, $q) {
          const deferred = $q.defer();

          if ($stateParams.catalogId) {
            const isJourney = $stateParams.isJourney ?? false;
            CurrentCourseManager.requestCourse($stateParams.catalogId, true, false, isJourney).then(
              () => { deferred.resolve(); },
              () => { deferred.resolve(); },
            );
          } else {
            deferred.resolve();
          }

          return deferred.promise;
        },
        requestInstitution: (InstitutionsManager) => InstitutionsManager.getBasicWalledGardenInfo(),
      },
      data: {
        level: 1,
        requiresLogin: false,
      },
    })

    .state('users.authentications.verify-user', {
      abstract: true,
      template: '<div ui-view></div>',
      resolve: {
        authenticateUser: (UserAuthentication, $q, $state, CurrentUserManager, StateManager) => {
          const deferred = $q.defer();

          UserAuthentication.verifyAuthenticated().then(() => {
            $('.loading-screen').hide();

            if (StateManager.lastStateAttempted.name === 'users.authentications.terms') {
              deferred.resolve();
            } else if (CurrentUserManager.currentInstitution && CurrentUserManager.getInstitutionAdmin(CurrentUserManager.currentInstitution.id)) {
              $state.go('institution-dashboard', { institutionId: CurrentUserManager.currentInstitution.id });
            } else if (CurrentUserManager.hasLoggedIn()) {
              $state.go('dashboard');
            } else {
              deferred.resolve();
            }
          });

          return deferred.promise;
        },
      },
    })
    .state('users.authentications.sign-in', {
      parent: 'users.authentications.verify-user',
      url: '/sign_in?alreadyJoined',
      controller: 'UserSignInCtrl as vm',
      templateUrl: 'users/templates/sign-in.html',
    })
    .state('users.authentications.sign-up', {
      parent: 'users.authentications.verify-user',
      url: '/sign_up',
      controller: 'UserSignUpCtrl as vm',
      templateUrl: 'users/templates/sign-up.html',
      resolve: {
        checkProviders: (authenticateUser, requestInstitution, InstitutionsManager, UserAuthentication, $window, _, $state, $stateParams) => {
          if (InstitutionsManager.institution?.ssoLogin && !_.isEmpty(InstitutionsManager.institution.identityProviders)) {
            if (InstitutionsManager.institution.identityProviders.length === 1) {
              $window.location = UserAuthentication.getSSOSignInUrl(InstitutionsManager.institution.identityProviders[0].name);
            } else {
              $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId, mentor: $stateParams.mentor, plan: $stateParams.plan });
            }
          }
        },
      },
    })
    .state('users.authentications.request-password-reset-email-abstract', {
      parent: 'users.authentications.verify-user',
      abstract: true,
      controller: 'RequestPasswordResetCtrl as vm',
      templateUrl: 'users/templates/request-password-reset-email-abstract.html',
    })
    .state('users.authentications.request-password-reset-email', {
      parent: 'users.authentications.request-password-reset-email-abstract',
      url: '/passwords/request_password_reset?linkExpired',
      templateUrl: 'users/templates/request-password-reset-email.html',
      resolve: {
        checkProviders: (authenticateUser, requestInstitution, InstitutionsManager, UserAuthentication, $window, _, $state) => {
          const state = store.getState();
          const { withPassword } = state.app.loginProviders;
          if (
            InstitutionsManager.institution
            && InstitutionsManager.institution.ssoLogin
            && !_.isEmpty(InstitutionsManager.institution.identityProviders)
            && !withPassword
          ) {
            if (InstitutionsManager.institution.identityProviders.length === 1) {
              $window.location = UserAuthentication.getSSOSignInUrl(InstitutionsManager.institution.identityProviders[0].name);
            } else {
              $state.go('users.authentications.sign-in');
            }
          }
        },
      },
    })
    .state('users.authentications.confirm-request-password-reset-email', {
      parent: 'users.authentications.request-password-reset-email-abstract',
      url: '/passwords/request_password_reset_confirm',
      templateUrl: 'users/templates/confirm-request-password-reset-email.html',
      resolve: {
        checkProviders: (authenticateUser, requestInstitution, InstitutionsManager, UserAuthentication, $window, _, $state) => {
          const state = store.getState();
          const { withPassword } = state.app.loginProviders;
          if (
            InstitutionsManager.institution
            && InstitutionsManager.institution.ssoLogin
            && !_.isEmpty(InstitutionsManager.institution.identityProviders)
            && !withPassword
          ) {
            if (InstitutionsManager.institution.identityProviders.length === 1) {
              $window.location = UserAuthentication.getSSOSignInUrl(InstitutionsManager.institution.identityProviders[0].name);
            } else {
              $state.go('users.authentications.sign-in');
            }
          }
        },
      },
    })
    .state('users.authentications.reset-password', {
      parent: 'users.authentications.verify-user',
      controller: 'ResetPasswordCtrl as vm',
      url: '/passwords/reset_password?resetPasswordToken',
      templateUrl: 'users/templates/reset-password.html',
    })
    .state('org-level-profile-modal', {
      url: '/users/{userId:int}',
      parent: 'modal-page',
      views: {
        'modal-content@modal-page': {
          templateUrl: 'org_level_profile/templates/org-level-profile.html',
          controller: 'OrgLevelProfileCtrl as vm',
        },
        'modal-page-header@modal-page': {
          templateUrl: 'org_level_profile/templates/org-level-profile-content-header.html',
          controller: 'OrgLevelProfileContentHeaderCtrl as vm',
        },
      },
      data: {
        level: 4,
        titleKey: 'ORG_LEVEL_PROFILE.PROFILE',
        modalPage: {
          noBorder: true,
          noPadding: true,
        },
        tabTitleOption: 'title_institution',
      },
    })
    .state('org-level-profile-page', {
      url: '/users/{userId:int}',
      parent: 'lightbox',
      views: {
        'top-header@mainGridContent': {
          templateUrl: 'shared/templates/org-l3-header.html',
          controller: 'OrgL3HeaderCtrl as vm',
        },
        'lightbox-main@lightbox': {
          templateUrl: 'org_level_profile/templates/org-level-profile.html',
          controller: 'OrgLevelProfileCtrl as vm',
        },
        'lightbox-header@lightbox': {
          templateUrl: 'org_level_profile/templates/org-level-profile-content-header.html',
          controller: 'OrgLevelProfileContentHeaderCtrl as vm',
        },
      },
      data: {
        level: 3,
        titleKey: 'ORG_LEVEL_PROFILE.PROFILE',
        lightbox: {
          noBorder: true,
          noPadding: true,
        },
        orgL3Header: {
          subtitleTranslation: 'ORG_LEVEL_PROFILE.PROFILE',
        },
        tabTitleOption: 'title_institution',
      },
      resolve: {
        checkOrgBelonging,
      },
    })
    /**
     * Inside a Journey
     */
    .state('journey-wrapper', {
      abstract: true,
      url: '/journeys/:catalogId',
      parent: 'user-grid-abstract',
      views: {
        'main-panel@user-grid-abstract': {
          templateUrl: 'layouts/templates/main-content.html',
        },
      },
      data: {
        level: 1,
      },
    })
    .state('journey-lightbox', {
      abstract: true,
      parent: 'journey-wrapper',
      views: {
        main: {
          templateUrl: 'layouts/templates/nv-lightbox-main.html',
          controller: 'StickyHeaderFooterCtrl as stickyHeaderFooterCtrl',
        },
      },
    })
    .state('learning-journey', {
      abstract: true,
      parent: 'journey-wrapper',
      views: {
        'main@journey-wrapper': {
          controller: 'LearningJourneyCtrl',
          templateUrl: 'learning_journeys/templates/learning_journey.html',
        },
      },
      data: {
        headerClass: 'no-header',
      },
      onExit($timeout, CurrentCourseManager, StateManager) {
        if (!StateManager.paramsForLastStateAttempted.catalogId) {
          $timeout(() => {
            CurrentCourseManager.resetCourse();
            store.dispatch(unsetCurrentCatalogId());
          });
        }
      },
    })
    .state('learning-journey-home', {
      url: '/home',
      parent: 'learning-journey',
    })
    .state('learning-journey-user-management', {
      abstract: true,
      parent: 'learning-journey',
      url: '/user-management?{roleFilter:int}',
      params: {
        forceReload: false,
        initialState: false,
      },
      data: {
        mainClass: 'user-management-main',
      },
    })
    .state('learning-journey-user-management-learners', {
      parent: 'learning-journey-user-management',
      url: '/learners',
      data: {
        userTypeFilter: 1, // Learners
      },
    })
    .state('learning-journey-user-management-admins', {
      parent: 'learning-journey-user-management',
      url: '/admins',
      data: {
        userTypeFilter: 0, // Admins
      },
    })
    .state('learning-journey-user-management-search', {
      parent: 'learning-journey-user-management',
      url: '/search?{query:string}',
      data: {
        userTypeFilter: 2, // All (Search)
      },
    })
    .state('learning-journey-user-management-mentors', {
      parent: 'learning-journey-user-management',
      url: '/mentors',
      data: {
        userTypeFilter: 3, // Mentors
      },
    })
    /* Manage Team / Groups */
    .state('manage-team-group-modal', {
      url: '/manage-team-group/:catalogId/:teamId',
      parent: 'modal-page',
      views: {
        'modal-content@modal-page': {
          templateUrl: 'manage_team_group/templates/react-app.html',
          controller: 'ManageTeamGroupReactCtrl as vm',
        },
      },
      data: {
        mainClass: 'manage-team-group',
        modalMaskClass: 'manage-team-group-modal-mask',
        level: 4,
        modalPage: {
          noBorder: true,
          noPadding: true,
        },
      },
    })
    /* Practice Room Routes */
    .state('practice-room-modal', {
      url: '/practice-room/:scenarioId/:base',
      parent: 'modal-page',
      // reloadOnSearch: false,
      params: {
        // This base param is important to load this route even it got a
        // trailing slash, added by the react routing
        base: {
          value: null,
          squash: true,
        },
        submission: null,
        user: null,
        selected: null,
        commentId: null,
        galleryMode: true,
        submissionView: null,
        feedbackBy: null,
        stickyInsights: null,
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'practice_room/templates/react-app.html',
          controller: 'PracticeRoomReactCtrl as vm',
        },
      },
      data: {
        titleKey: 'PRACTICE_ROOM.HEADER',
        mainClass: 'practice-room',
        modalMaskClass: 'practice-room-modal-mask',
        level: 4,
        modalPage: {
          noBorder: true,
          noPadding: true,
        },
      },
    });

  function requestJourney(
    $q,
    $state,
    $stateParams,
    AlertMessages,
    CurrentUserManager,
    CurrentCourseManager,
  ) {
    const deferred = $q.defer();
    const requestCoursePromise = CurrentCourseManager.requestCourse(
      $stateParams.catalogId,
      true,
      null,
      true, // Fetches the journey from journeys API and not from courses API
    );

    $q.all([requestCoursePromise, CurrentUserManager.requestCurrentUserPromise]).then(
      (resolves) => {
        const [course] = resolves;
        deferred.resolve(course);
      },
      (error) => {
        if (error.status === 404) {
          $state.go('dashboard').then(() => {
            AlertMessages.error('', 'COURSE_HOME.HEADER.COURSE_ACCESS_DENIED');
          });
        }
      },
    );
    return deferred.promise;
  }

  $stateProvider
    /**
     * Created 'course-lightbox' and 'modal-page' states with 'angular-journey' prefix
     * meaning that they fetch the journey (journeys are courses but with extra
     * features) from AngularJS in order to make 'journey-statements-modal'
     * and 'journey-statements-page' states work since they reuse the AngularJS
     * logic from 'statements-page' and 'statements-modal'.
     * We need to get the AngularJS journey through Angular because there's not
     * an existing parent state that does that, since Learning Journeys project
     * is mostly built in react and data is directly fetched there.
     */
    .state('angular-journey-modal-page', {
      abstract: true,
      parent: 'modal-page',
      url: '/journeys/:catalogId',
      resolve: {
        requestAngularJourney: requestJourney,
      },
      onExit(CurrentCourseManager, StateManager, CurrentUserManager) {
        const currentCatalogId = StateManager.paramsForLastStateAttempted.catalogId;
        if (currentCatalogId) {
          CurrentCourseManager.resetCourse();

          const currentCourse = CurrentUserManager.coursesHashByCatalogId[currentCatalogId];

          CurrentCourseManager.setCourse(currentCourse);
        }
      },
    })
    .state('angular-journey-lightbox', {
      abstract: true,
      parent: 'journey-lightbox',
      resolve: {
        requestAngularJourney: requestJourney,
      },
      onExit(CurrentCourseManager) {
        CurrentCourseManager.resetCourse();
      },
    })
    .state('journey-statement-modal', {
      parent: 'angular-journey-modal-page',
      url: '/statements/{userId:int}',
      views: {
        'modal-content@modal-page': {
          templateUrl: 'statements/templates/statement.html',
          controller: 'StatementCtrl as vm',
        },
      },
      data: {
        mainClass: 'statement',
        level: 4,
      },
      resolve: {
        requestStatement(
          $stateParams,
          StatementsManager,
          requestAngularJourney,
        ) {
          return StatementsManager.requestStatementForuser($stateParams.catalogId, $stateParams.userId);
        },
      },
    })
    .state('journey-statement-page', {
      parent: 'angular-journey-lightbox',
      url: '/statements/{userId:int}',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'StatementBaseCtrl as vm',
        },
        'lightbox-main@journey-lightbox': {
          templateUrl: 'statements/templates/statement.html',
          controller: 'StatementCtrl as vm',
        },
      },
      data: {
        mainClass: 'statement',
        level: 3,
        requiresLogin: false,
      },
      resolve: {
        requestStatement(
          $stateParams,
          StatementsManager,
          requestAngularJourney,
        ) {
          return StatementsManager.requestStatementForuser($stateParams.catalogId, $stateParams.userId);
        },
      },
    })
    /** Inside a Course * */
    .state('course-wrapper', {
      parent: 'user-grid-abstract',
      url: '/courses/:catalogId',
      abstract: true,
      resolve: {
        requestCourse(
          $state,
          $stateParams,
          StateManager,
          CurrentCourseManager,
          CurrentUserManager,
          $window,
          $q,
          $location,
          config,
          RailsRoutes,
          AlertMessages,
        ) {
          const deferred = $q.defer();

          const requestDomainInfoPromise = CurrentCourseManager.requestCourseDomainInfo($stateParams.catalogId);

          requestDomainInfoPromise.then((response) => {
            const correctDomainForCourse = response.result.host;
            const currentUrlHost = $location.host();

            if (correctDomainForCourse !== currentUrlHost) {
              const href = $state.href(StateManager.lastStateAttempted.name, StateManager.paramsForLastStateAttempted);
              const newUrl = `${$location.protocol()}://${correctDomainForCourse}/${href}`;
              $window.location = newUrl;
            }
          });
          const state = store.getState();
          const journey = state.app.visitedLearningJourney;

          CurrentCourseManager.requestCoursePromise = CurrentCourseManager.requestCourse($stateParams.catalogId, true, null, null, journey.id)
            .catch((response) => {
              if (response.data?.error?.code === 'error.catalog_id_is_journey_type') {
                $state.go('learning-journey-home', { catalogId: $stateParams.catalogId });
              } else if (response.status === 404) {
                $state.go('root').finally(() => {
                  AlertMessages.error('', 'COURSE_HOME.HEADER.COURSE_ACCESS_DENIED');
                });
              } else if (response.status === 401) {
                $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
              }

              return Promise.reject(response);
            });

          $q.all([CurrentCourseManager.requestCoursePromise, CurrentUserManager.requestCurrentUserPromise]).then(
            (resolves) => {
              const [course] = resolves;
              deferred.resolve(course); // this may return an empty object
            },
          );

          return deferred.promise;
        },
      },
      onEnter(
        CurrentCourseManager,
        CurrentUserManager,
        requestCourse,
      ) {
        CurrentCourseManager.setCourse(requestCourse);

        let currentCourse = null;
        if (CurrentUserManager.hasLoggedIn()) {
          currentCourse = CurrentUserManager.coursesHashByCatalogId[requestCourse.catalogId];
        } else {
          currentCourse = CurrentCourseManager.course;
        }

        if (currentCourse?.institution.analyticsIdentifier) {
          lastL1TrackerName = currentCourse.institution.id;
          window.ga('create', currentCourse.institution?.analyticsIdentifier, 'auto', lastL1TrackerName);
        }
      },
      onExit(
        StateManager,
        CurrentCourseManager,
        CurrentUserManager,
      ) {
        if (!StateManager.paramsForLastStateAttempted.catalogId) {
          CurrentCourseManager.resetCourse();
          CurrentUserManager.resetCurrentUserCourse();
        }

        window.ga(`${lastL1TrackerName}.remove`);
      },
    })
    .state('course', {
      parent: 'course-wrapper',
      views: {
        'main-panel@user-grid-abstract': {
          template: '<ui-view/>',
        },
      },
      resolve: {
        checkCoursePermission(
          requestCourse,
          StateManager,
          CurrentUserManager,
          CurrentPermissionsManager,
          $window,
          RailsRoutes,
          $stateParams,
          $state,
          $q,
        ) {
          const course = requestCourse;

          if (!course) {
            if (CurrentUserManager.hasLoggedIn()) {
              $state.go('dashboard');
            } else {
              $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
            }
          } else {
            const noLoginRequired = StateManager?.lastStateAttempted.data.requiresLogin === false && course.publiclySharedFlyer;
            const canAccessCollection = course.isContentManagementCollection && (
              CurrentPermissionsManager.hasCollectionViewerPermissions()
              || CurrentPermissionsManager.hasCourseManagerPermissions());

            const regularAccessPermitted = (course.released && (noLoginRequired || (course.userCourse?.isEnrolled))) || canAccessCollection;

            const privilegedAccessPermitted = !course.isContentManagementCollection && (
              CurrentUserManager.isAdmin() || CurrentPermissionsManager.hasOrgMentorPermissions());

            if (regularAccessPermitted || privilegedAccessPermitted) {
              if (course.userCourse?.termsAcceptanceNeeded && !$stateParams.mobileapp) {
                $window.location = RailsRoutes.termsPath($stateParams.catalogId);
              } else {
                return $q.when(course);
              }
            } else if (course.paidAndOpenEnrollment() && course.userCourse && !course.userCourse.paid) {
              $window.location = RailsRoutes.newTransactionPath($stateParams.catalogId);
            } else if (course.isContentManagementCollection) {
              $state.go('content-library-collections', null, { replace: true });
            } else {
              $state.go('course-flyer', { catalogId: $stateParams.catalogId });
            }
          }

          return null;
        },
      },
    })
    .state('course-flyer', {
      url: '/flyer',
      params: {
        referralToken: null,
      },
      parent: 'course-wrapper',
      views: {
        'page-content@app-wrapper': {
          templateUrl: 'flyers/templates/flyer.html',
          controller: 'FlyerCtrl as vm',
        },
      },
      data: {
        requiresLogin: false,
      },
      resolve: {
        checkFlyerRedirect(
          requestCourse,
          CurrentUserManager,
          $state,
          $stateParams,
        ) {
          const course = requestCourse;

          if (!course) {
            if (CurrentUserManager.hasLoggedIn()) {
              $state.go('dashboard');
            } else {
              $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
            }
          }
        },
      },
    })
    .state('course-mainGridContent', {
      abstract: true,
      parent: 'course',
      templateUrl: 'layouts/templates/main-content.html',
      controller: 'MainContentCtrl as vm',
      data: {
      },
      onExit: ($timeout, StateManager) => {
        // Reseting the visited journey when the user exits the course
        $timeout(() => {
          if (StateManager.lastStateEntered.name !== 'course-home') {
            store.dispatch(updateVisitedJourney(initialVisitedLearningJourney));
          }
        });
      },
    })
    .state('course-home-abstract', {
      abstract: true,
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'course_home/templates/header.html',
          controller: 'CourseHomeBaseCtrl as vm',
        },
        'extra-top-nav-content': {
          templateUrl: 'course_home/templates/extra-top-nav-content.html',
          controller: 'CourseHomeBaseCtrl as vm',
        },
        main: {
          templateUrl: 'course_home/templates/main.html',
          controller: 'CourseHomeCtrl as vm',
        },
        'mobile-right@user-grid-abstract': {
          templateUrl: 'course_home/templates/course-home-rhs.html',
          controller: 'CourseHomeRhsCtrl as vm',
        },
        'desktop-right@user-grid-abstract': {
          templateUrl: 'course_home/templates/course-home-rhs.html',
          controller: 'CourseHomeRhsBaseCtrl as vm',
        },
      },
      resolve: {
        requestCourseHome(
          $stateParams,
          RailsRoutes,
          CourseHomeManager,
          CurrentCourseManager,
          CurrentUserManager,
          $state,
          requestCourse,
          checkCoursePermission,
        ) {
          return CourseHomeManager.getCourseHome($stateParams.catalogId)
            .then((response) => {
              // if a program, go to program home instead
              if (requestCourse.isProgram) {
                $state.go('program-home', { catalogId: $stateParams.catalogId }, { location: 'replace' });
              }
            }, (errResponse) => {
              if (CurrentUserManager.hasLoggedIn()) {
                if (requestCourse.isContentManagementCollection) {
                  $state.go('content-library-collections', null, { replace: true });
                } else {
                  $state.go('course-flyer', { catalogId: $stateParams.catalogId }, { location: 'replace' });
                }
              }
            });
        },
      },
      data: {
        headerClass: 'course_home',
        hasInstitutionBackgroundHeader: true,
        mainContent: {
          headerNoPadding: true,
        },
      },
    })
    .state('course-home', {
      url: '/home?timelineState',
      parent: 'course-home-abstract',
      views: {
        main: {
          templateUrl: 'timelines/templates/course-home-timeline.html',
          controller: 'TimelineShowCtrl as vm',
        },
        timeline: {
          templateUrl: 'timelines/templates/course-home-timeline.html',
          controller: 'TimelineShowCtrl as vm',
        },
        'top-header': {
          templateUrl: 'course_home/templates/header.html',
        },
      },
      data: {
        titleKey: 'COURSE_HOME.HEADER.COURSE_HOME',
        level: 1,
        leftNavState: 'expanded',
        hasRhs: true,
        mainPanelScrollable: true,
        hasInstitutionBackgroundHeader: true,
        tabTitleOption: 'title_course',
      },
      resolve: {
        /**
         * The purpose of this resolve is purely for the sake of fetching the course outline code ASAP
         * if we wait for the call inside the ctrl, the user has to wait an extra 500ms for the home.json + angularjs processing
         */
        fetchTimeline(
          authenticateUser,
          TimelinesManager,
          $stateParams,
          CurrentUserManager,
          CurrentCourseManager,
          requestCourse,
          checkCoursePermission,
        ) {
          if (CurrentUserManager.user.translationPreferenceLanguage && CurrentCourseManager.course?.automatedTranslationEnabled) {
            TimelinesManager.translateTimeline($stateParams.catalogId, CurrentUserManager.user.translationPreferenceLanguage)
              .then(() => {});
          }
        },
      },
      onEnter: (TimelinesManager) => {
        TimelinesManager.mode = 'view';
      },
    })
    .state('course-home-edit', {
      url: '/home/edit?timelineState',
      parent: 'course-home-abstract',
      views: {
        main: {
          templateUrl: 'timelines/templates/course-home-timeline.html',
          controller: 'TimelineShowCtrl as vm',
        },
        timeline: {
          templateUrl: 'timelines/templates/course-home-timeline.html',
          controller: 'TimelineShowCtrl as vm',
        },
        'top-header': {
          templateUrl: 'course_home/templates/header.html',
        },
      },
      data: {
        titleKey: 'COURSE_HOME.HEADER.COURSE_HOME',
        level: 1,
        leftNavState: 'expanded',
        hasRhs: true,
        mainPanelScrollable: true,
        hasInstitutionBackgroundHeader: true,
        tabTitleOption: 'title_course',
      },
      onEnter: (TimelinesManager) => {
        TimelinesManager.mode = 'edit';
      },
      onExit: (TimelinesManager) => {
        TimelinesManager.mode = 'view';
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.isCourseBuilder(), 'course-home'),
        fetchTimeline(
          authenticateUser,
          TimelinesManager,
          $stateParams,
          CurrentUserManager,
          CurrentCourseManager,
          requestCourse,
          checkCoursePermission,
        ) {
          if (CurrentUserManager.user.translationPreferenceLanguage) {
            store.dispatch(unsetTranslationPreference()).then(() => {
              CurrentUserManager.user.translationPreferenceLanguage = null;

              TimelinesManager.getTimeline(
                $stateParams.catalogId,
                CurrentUserManager.user.id,
                CurrentUserManager.isAdmin(),
                CurrentCourseManager.course,
                { forceLoad: true, editMode: true },
              );
            });
          } else {
            TimelinesManager.getTimeline(
              $stateParams.catalogId,
              CurrentUserManager.user.id,
              CurrentUserManager.isAdmin(),
              CurrentCourseManager.course,
              { forceLoad: true, editMode: true },
            );
          }
        },
      },
    })
    .state('announcements', {
      url: '/announcements',
      parent: 'course-mainGridContent',
      resolve: {
        redirectToCourseComms(
          $q,
          $stateParams,
        ) {
          return $q.reject({
            redirectTo: 'course-communications-edit',
            redirectParams: { catalogId: $stateParams.catalogId, tab: 'scheduled' },
          });
        },
      },
    })
    .state('program-timelines', {
      url: '/resources',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'timelines/templates/light-header.html',
          controller: 'CourseHomeBaseCtrl as vm',
        },
        main: {
          templateUrl: 'timelines/templates/program-timeline.html',
          controller: 'TimelineShowCtrl as vm',
        },
      },
      data: {
        titleKey: 'COURSE_HOME.HEADER.RESOURCES',
        level: 1,
        leftNavState: 'expanded',
        mainClass: 'program-timelines l1-main',
        mainPanelScrollable: true,
        headerClass: 'light-header fixed-header directory-page-header',
        hasRhs: false,
      },
      onEnter: (TimelinesManager) => {
        TimelinesManager.mode = 'view';
      },
    })
    .state('program-timelines-edit', {
      url: '/resources/edit',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'timelines/templates/light-header.html',
          controller: 'CourseHomeBaseCtrl as vm',
        },
        main: {
          templateUrl: 'timelines/templates/program-timeline.html',
          controller: 'TimelineShowCtrl as vm',
        },
      },
      data: {
        titleKey: 'COURSE_HOME.HEADER.RESOURCES',
        level: 1,
        leftNavState: 'expanded',
        mainClass: 'program-timelines l1-main',
        mainPanelScrollable: true,
        headerClass: 'light-header fixed-header directory-page-header',
        hasRhs: false,
      },
      onEnter: (TimelinesManager) => {
        TimelinesManager.mode = 'edit';
      },
      onExit: (TimelinesManager) => {
        TimelinesManager.mode = 'view';
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.isCourseBuilder(), 'program-timelines'),
        fetchTimeline(
          TimelinesManager,
          $stateParams,
          CurrentUserManager,
          CurrentCourseManager,
        ) {
          TimelinesManager.getTimeline(
            $stateParams.catalogId,
            CurrentUserManager.user.id,
            CurrentUserManager.isAdmin(),
            CurrentCourseManager.course,
            { forceLoad: true },
          );
        },
      },
    })
    .state('leaderboard', {
      url: '/leaderboard',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'leaderboard/templates/leaderboard-header.html',
        },
        main: {
          templateUrl: 'leaderboard/templates/leaderboard.html',
          controller: 'LeaderboardCtrl as vm',
        },
      },
      data: {
        titleKey: 'GAMIFICATION.LEADERBOARD',
        level: 1,
        leftNavState: 'expanded',
        mainClass: 'leaderboard l1-main',
        mainPanelScrollable: true,
        headerClass: 'light-header fixed-header directory-page-header',
        hasRhs: false,
        tabTitleOption: 'title_course',
      },
    })
    .state('submissions-gallery', {
      url: '/submission-gallery?{exerciseId:int}&filterOption',
      parent: 'course-mainGridContent',
      deepStateRedirect: true,
      params: {
        exerciseId: null,
        filterOption: null,
        reload: null,
      },
      views: {
        'top-header': {
          templateUrl: 'submissions/templates/submission-gallery-course-header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'submissions/templates/nv-submission-sort-filter-header.html',
          controller: 'SubmissionSortFilterCtrl as vm',
        },
        main: {
          templateUrl: 'submissions/templates/submission-gallery-main.html',
          controller: 'SubmissionGalleryCtrl as vm',
        },
      },
      data: {
        level: 1,
        titleKey: 'SUBMISSIONS.TITLE',
        headerClass: 'light-header submissions-header fixed-header directory-page-header',
        mainClass: 'submission-gallery-main directory-page-main',
        hasRhs: false,
        leftNavState: 'collapsed',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
        tabTitleOption: 'title_course',
      },
    })
    .state('custom-downloads', {
      url: '/custom_downloads?{sectionId:string}',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'custom_downloads/templates/custom-downloads-header.html',
        },
        main: {
          templateUrl: 'custom_downloads/templates/custom-downloads-main.html',
          controller: 'CustomDownloadsCtrl as vm',
        },
      },
      data: {
        level: 1,
        leftNavState: 'expanded',
        mainClass: 'custom-downloads l1-main',
        mainPanelScrollable: true,
        headerClass: 'light-header fixed-header directory-page-header',
        hasRhs: false,
      },
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.isReportAdmin(), 'course-home'),
      },
    })
    .state('course-communications-edit', {
      url: '/course_communications?{tab:string}',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'communications/course_communications/templates/course-communications-header.html',
        },
        main: {
          templateUrl: 'communications/course_communications/templates/course-communications.html',
          controller: 'CourseCommunicationsReactCtrl as vm',
        },
      },
      data: {
        level: 1,
        leftNavState: 'expanded',
        mainClass: 'l1-main',
        mainPanelScrollable: true,
        headerClass: 'light-header fixed-header directory-page-header',
        hasRhs: false,
      },
      resolve: {
        checkIsEnrolled(
          CurrentCourseManager,
          requestCourse,
          $state,
          $stateParams,
        ) {
          // Adding course from CurrentCourseManager for those cases where requestCourse doesn't have
          // the latest data of the current course.
          const course = { ...requestCourse, ...CurrentCourseManager?.course };

          if (!course?.userCourse?.isEnrolled) {
            $state.go('course-home', { catalogId: $stateParams.catalogId });
          }
        },
        checkPermission: redirectIfUnauthorized((manager) => manager.isCourseBuilder() || manager.isInstructor(), 'course-home'),
      },
    })
    .state('course-lightbox', {
      abstract: true,
      parent: 'course-mainGridContent',
      views: {
        main: {
          templateUrl: 'layouts/templates/nv-lightbox-main.html',
          controller: 'StickyHeaderFooterCtrl as stickyHeaderFooterCtrl',
        },
      },
      data: {
        hasRhs: false,
        headerClass: 'dark-header',
      },
    })
    .state('simple-lightbox', {
      abstract: true,
      parent: 'course-mainGridContent',
      views: {
        main: {
          templateUrl: 'layouts/templates/nv-simple-lightbox-main.html',
          controller: 'StickyHeaderFooterCtrl as stickyHeaderFooterCtrl',
        },
      },
      data: {
        headerClass: 'light-header fixed-header l2-header',
        hasRhs: false,
      },
    })

    .state('lecture-page-parent', {
      abstract: true,
      url: '/lecture_pages',
      parent: 'course-mainGridContent',
      controller: 'LecturePageReactCtrl as vm',
      views: {
        main: {
          templateUrl: 'lecture_pages/templates/lecture-page-react-app.html',
          controller: 'LecturePageReactCtrl as vm',
        },
        'top-header@course-mainGridContent': {
          templateUrl: 'lecture_pages/templates/light-header.html',
          controller: 'LectureLightHeaderCtrl as vm',
        },
      },
      data: {
        level: 2,
        headerClass: 'light-header fixed-header l2-header',
        hasRhs: false,
      },
    })
    // This child state only exists to "trick" angular-ui-router into not re-creating the
    // LecturePageReactCtrl when the URL params change. Failing to use this will cause all of our
    // Lecture page data models to be reloaded on every url change in this view
    .state('lecture-page', {
      url: '/{id:int}?{lectureActivityId:int}&{fromLinkedCourse:bool}',
      params: {
        lectureActivityId: null,
        reportId: null,
        revise: false,
      },
      parent: 'lecture-page-parent',
      data: {
        // Note that lecture-page-edit is optionally added in the MainGridCtrl
        mainClass: 'lecture-page learner-exercise l2-main lightbox',
        // This is for customising the main panel class
        mainPanelClass: 'lecture-page-main-panel',
      },
    })
    /** This second state exists only to solve a bug caused by needing to support matching against this
     * page state even when the url ends in a slash '/'. Writing the rule as '/{id:int}{rest:.*}' (no slash)
     * will cause the slash to be part of the 'rest' params, and represented as an HTML encoded string in
     * urls to angular-ui-router states inside the components. */
    .state('lecture-page-slash', {
      url: '/{id:int}/{rest:.*}?{lectureComponentToEdit:int}',
      parent: 'lecture-page-parent',
      data: {
        mainClass: 'lecture-page learner-exercise l2-main lightbox',
      },
      resolve: {
        redirect(
          $state,
          $stateParams,
        ) {
          // Remove the trailing slash and redirect to the no-params router state if 'rest' is empty
          if (!$stateParams.rest.length) {
            $state.go('lecture-page', $stateParams);
          }
        },
      },
    })
    /** These two states only exist as shims for existing Angularjs code that references states
     * by these names. These defer all decision making to lecture-page-parent and accomplish nothing
     * beyond setting the correct url when used via the ui-sref attribute */
    .state('lecture-page-edit', {
      parent: 'lecture-page-parent',
      url: '/{id:int}/edit?{lectureActivityId:int}&{lectureComponentToEdit:int}&{fromLinkedCourse:bool}',
    })
    .state('lecture-page-reorder', {
      parent: 'lecture-page-parent',
      url: '/{id:int}/reorder',
    })
    .state('lecture-video', {
      url: '/lecture_video_lists/{videoListId:int}/lecture_videos/{id:int}',
      parent: 'course-lightbox',
      params: { useExisting: false },
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'LectureVideosBaseCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'lecture_videos/templates/lecture-video-lightbox-header.html',
          controller: 'LectureVideosBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'lecture_videos/templates/lecture-video-main.html',
          controller: 'LectureVideosCtrl as vm',
        },
      },
      data: {
        titleKey: 'LECTURE_VIDEO.HEADER',
        level: 3,
        headerClass: 'dark-header',
        // learner-exercise is needed for the lightbox
        mainClass: 'lecture-videos learner-exercise',
      },
      resolve: {
        requestLectureVideo(
          $stateParams,
          LectureVideosManager,
        ) {
          return LectureVideosManager.getLectureVideo($stateParams.catalogId, $stateParams.videoListId, $stateParams.id, $stateParams.useExisting);
        },
      },
    })
    .state('exercise', {
      url: '/exercises/{exerciseId:int}',
      abstract: true,
      parent: 'course-lightbox',
      resolve: {
        requestExercise(
          $stateParams,
          $state,
          ExercisesResources,
          CurrentUserManager,
          requestCourse,
        ) {
          return ExercisesResources.get({ catalogId: $stateParams.catalogId, id: $stateParams.exerciseId }).$promise.then((response) => {
            if (!CurrentUserManager.hasLoggedIn() || !CurrentUserManager.hasEnrolledInCourse(requestCourse)) {
              $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
            } else if (requestCourse.inArchiveMode) {
              $state.go('course-home', { catalogId: $stateParams.catalogId });
            } else {
              $state.go('lecture-page', { catalogId: $stateParams.catalogId, id: response.result.lecturePageId, lectureActivityId: response.result.lectureComponentId });
            }
          }, () => {
            $state.go('course-home', { catalogId: $stateParams.catalogId });
          });
        },
      },
      data: {
        headerClass: 'exercise-header dark-header',
        mainClass: 'learner-exercise',
      },
    })
    .state('individual-submission-page', {
      parent: 'course-lightbox',
      url: '/courses/:catalogId/reports/{reportId:int}?{commentId:int}&{replyId:int}&{selected}&{isRatingFeedback:bool}&{userId:int}',
      params: {
        selected: null,
        isRatingFeedback: null,
        userId: null,
      },
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'exercises/templates/learner-exercise-header.html',
          controller: 'IndividualSubmissionBaseCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'submissions/templates/nv-individual-submission-expanded-header.html',
          controller: 'IndividualSubmissionBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'submissions/templates/nv-individual-submission-wrapper.html',
          controller: 'IndividualSubmissionBaseCtrl as vm',
        },
        'lightbox-bottom@course-lightbox': {
          templateUrl: 'submissions/templates/nv-individual-submission-footer.html',
          controller: 'IndividualSubmissionBaseCtrl as vm',
        },
      },
      data: {
        mainClass: 'single-submission',
        headerClass: 'exercise-header',
        level: 3,
        requiresLogin: false,
      },

    })
    .state('individual-submission-modal', {
      parent: 'modal-page',
      url: '/courses/:catalogId/reports/{reportId:int}?galleryMode&{showCourseHeader:bool}&{commentId:int}&{replyId:int}&{selected}&{isRatingFeedback:bool}&{userId:int}',
      params: {
        galleryMode: null,
        allowMentions: null,
        hasRevised: null,
        showCourseHeader: false,
        selected: null,
        isRatingFeedback: false,
        userId: null,
      },
      views: {
        'modal-page-header@modal-page': {
          templateUrl: 'submissions/templates/nv-individual-submission-expanded-header.html',
          controller: 'IndividualSubmissionBaseCtrl as vm',
        },
        'modal-content@modal-page': {
          templateUrl: 'submissions/templates/nv-individual-submission-wrapper.html',
          controller: 'IndividualSubmissionBaseCtrl as vm',
        },
        'modal-pagination@modal-page': {
          templateUrl: 'layouts/templates/nv-modal-page-pagination-view.html',
          controller: 'IndividualSubmissionPaginationCtrl as vm',
        },
        'modal-page-bottom@modal-page': {
          templateUrl: 'submissions/templates/nv-individual-submission-footer.html',
          controller: 'IndividualSubmissionBaseCtrl as vm',
        },
      },
      data: {
        titleKey: 'EXERCISES.VIEW_SUBMISSION',
        mainClass: 'single-submission',
        level: 4,
      },
    })
    .state('l4_course', {
      url: '/courses/:catalogId',
      parent: 'modal-page',
      abstract: true,
      resolve: {
        requestCourse: (
          CurrentUserManager,
          $stateParams,
          $q,
        ) => {
          if (!CurrentUserManager.coursesHashByCatalogId[$stateParams.catalogId]) {
            return CurrentUserManager.requestCourse($stateParams.catalogId);
          }
          return $q.when();
        },
      },
    })
    .state('statement-modal', {
      parent: 'l4_course',
      url: '/statements/{userId:int}',
      views: {
        'modal-content@modal-page': {
          templateUrl: 'statements/templates/statement.html',
          controller: 'StatementCtrl as vm',
        },
      },
      data: {
        mainClass: 'statement',
        level: 4,
      },
      resolve: {
        requestStatement(
          $stateParams,
          $timeout,
          $state,
          StatementsManager,
          CurrentCourseManager,
          CurrentUserManager,
          requestCourse,
        ) {
          const { catalogId } = $stateParams;

          return StatementsManager.requestStatementForuser(catalogId, $stateParams.userId)
            .then((response) => response, () => {
              $state.go('dashboard');
            });
        },
      },
    })
    .state('statement-page', {
      parent: 'course-lightbox',
      url: '/statements/{userId:int}',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'StatementBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'statements/templates/statement.html',
          controller: 'StatementCtrl as vm',
        },
      },
      data: {
        mainClass: 'statement',
        level: 3,
        requiresLogin: false,
      },
      resolve: {
        requestStatement(
          $stateParams,
          $timeout,
          $state,
          CurrentCourseManager,
          StatementsManager,
          requestCourse,
        ) {
          const { catalogId } = $stateParams;
          return StatementsManager.requestStatementForuser(catalogId, $stateParams.userId)
            .then((response) => response, () => {
              // course should not be set until onenter, but code is too fragile to do that - NOV-37970 introduced it back
              CurrentCourseManager.resetCourse();
              $state.go('dashboard');
            });
        },
      },
    })
    .state('quiz-answers-modal', {
      parent: 'modal-page',
      url: '/courses/:catalogId/exercises/{exerciseId:int}/question_sets/{questionSetId:int}/quiz-answers',
      views: {
        'modal-page-header@modal-page': {
          templateUrl: 'quizzes/templates/quiz-answers-modal-header.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
        'modal-content@modal-page': {
          templateUrl: 'quizzes/templates/quiz-answers-main.html',
          controller: 'QuizAnswerFeedbackCtrl as vm',
        },
      },
      data: {
        titleKey: 'QUIZZES.ANSWERS',
        mainClass: 'quiz-answers',
        level: 4,
      },
    })
    .state('quiz-answers-page', {
      parent: 'quiz',
      url: '/quiz-answers',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
        'lightbox-main@lightbox': {
          templateUrl: 'quizzes/templates/quiz-answers-main.html',
          controller: 'QuizAnswerFeedbackCtrl as vm',
        },
        'lightbox-header@lightbox': {
          templateUrl: 'quizzes/templates/quiz-answers-lightbox-header.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
        'lightbox-bottom@lightbox': {
          templateUrl: 'quizzes/templates/new-quiz-lightbox-footer.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
      },
      data: {
        titleKey: 'QUIZZES.ANSWERS',
        level: 3,
        mainClass: 'quiz-answers',
      },
    })
    .state('quiz-feedback-modal', {
      parent: 'modal-page',
      url: '/courses/:catalogId/exercises/{exerciseId:int}/question_sets/{questionSetId:int}/quiz-feedback',
      views: {
        'modal-page-header@modal-page': {
          templateUrl: 'quizzes/templates/quiz-feedback-modal-header.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
        'modal-content@modal-page': {
          templateUrl: 'quizzes/templates/quiz-feedback-main.html',
          controller: 'QuizAnswerFeedbackCtrl as vm',
        },
      },
      data: {
        titleKey: 'QUIZZES.FEEDBACK',
        mainClass: 'quiz-feedback',
        level: 4,
      },
    })
    .state('quiz-feedback-page', {
      parent: 'quiz',
      url: '/quiz-feedback',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
        'lightbox-main@lightbox': {
          templateUrl: 'quizzes/templates/quiz-feedback-main.html',
          controller: 'QuizAnswerFeedbackCtrl as vm',
        },
        'lightbox-header@lightbox': {
          templateUrl: 'quizzes/templates/quiz-feedback-lightbox-header.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
        'lightbox-bottom@lightbox': {
          templateUrl: 'quizzes/templates/new-quiz-lightbox-footer.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
      },
      data: {
        titleKey: 'QUIZZES.FEEDBACK',
        level: 3,
        mainClass: 'quiz-feedback',
      },
    })
    .state('external-quiz', {
      url: '/external_quiz',
      parent: 'exercise',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'quizzes/templates/new-quiz-lightbox-header.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'quizzes/templates/external-quiz-lightbox-main.html',
          controller: 'QuizFormBaseCtrl as vm',
        },
      },
      data: {
        titleKey: 'EXERCISES.HEADER',
        level: 3,
        mainClass: 'external-quiz',
      },
    })
    .state('discussions-abstract', {
      parent: 'course-mainGridContent',
      abstract: true,
      views: {
        'top-header': {
          templateUrl: 'discussions/templates/light-header.html',

        },
        'extra-top-nav-content': {
          templateUrl: 'discussions/templates/nv-discussion-sort-filter-header.html',
          controller: 'DiscussionSortFilterCtrl as vm',
        },
        main: {
          templateUrl: 'discussions/templates/discussions-index-main.html',
          controller: 'DiscussionsIndexCtrl as vm',
        },
        'desktop-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
        'mobile-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
      },
      params: {
        topicId: 0,
        filterOption: null,
        sortOrder: null,
      },
      resolve: {
        redirectToProgramHome(
          CurrentCourseManager,
          requestCourse,
          checkCoursePermission,
          $stateParams,
          $state,
        ) {
          if (requestCourse.isProgram) {
            $state.go('program-home', $stateParams);
          }
        },
      },
    })
    .state('discussions-index', {
      url: '/discussions?{topicId:int}&filterOption&sortOrder',
      parent: 'discussions-abstract',
      params: {
        newTopicId: null, // hidden param to track new topic at top of RHS
      },
      data: {
        level: 1,
        titleKey: 'DISCUSSIONS.TITLE',
        headerClass: 'light-header fixed-header directory-page-header discussions-header',
        mainClass: 'directory-page-main',
        hasRhs: true,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
        tabTitleOption: 'title_course',
      },
    })
    .state('post-direct-link', {
      url: '/discussions/topics/{topicId:int}/posts/{postId:int}',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'discussions/templates/light-header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'discussions/templates/nv-discussion-sort-filter-header.html',
          controller: 'DiscussionSortFilterCtrl as vm',
        },
        main: {
          templateUrl: 'discussions/templates/discussions-index-main.html',
          controller: 'DirectLinkCtrl as vm',
        },
        'desktop-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
        'mobile-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
      },
      resolve: {
        initDiscussions(requestCourse, CurrentCourseManager, CurrentUserManager, DiscussionsManager, $state, $stateParams, _) {
          const course = requestCourse;
          if (!DiscussionsManager.catalogId
              || DiscussionsManager.catalogId !== $stateParams.catalogId
              || DiscussionsManager.context !== 'directLink') {
            DiscussionsManager.initialize({ catalogId: $stateParams.catalogId, context: 'directLink' });
          }

          DiscussionsManager.setDirectLinkHighlight(_.pick($stateParams, 'commentId', 'replyId'));
          DiscussionsManager.initializePusherEvents(course?.id);

          DiscussionsManager.getAllTopics($stateParams.topicId).then(() => {
            DiscussionsManager.setSortOrder($stateParams.sortOrder);
            DiscussionsManager.setFilterOption($stateParams.filterOption);
            DiscussionsManager.loadSinglePost($stateParams.postId).then(
              (post) => {
                DiscussionsManager.setPostData([post]);
                DiscussionsManager.loading = false;

                // if in a topic filter, mark as read here so that a page refresh will not show the "new" badges
                const selectedTopic = DiscussionsManager.findTopic($stateParams.topicId);
                if ($stateParams.topicId > 0
                      && ((!CurrentUserManager.isAdmin() && selectedTopic.getNumNewReleasedPosts() > 0)
                          || (CurrentUserManager.isAdmin() && selectedTopic.getNumNewPosts() > 0))) {
                  // mark those posts as read on the backend, but don't update the frontend yet
                  selectedTopic.markAsRead();
                }

                CurrentCourseManager.course.getTeachingTeamMembers();
                DiscussionsManager.initializeMentions();
              },
              (error) => {
                if (error.status === 404) {
                  $state.go('discussions-index', { topicId: $stateParams.topicId });
                }
              },
            );
          });
          DiscussionsManager.resetData();
        },
      },
      data: {
        level: 1,
        titleKey: 'DISCUSSIONS.TITLE',
        headerClass: 'light-header fixed-header directory-page-header discussions-header',
        mainClass: 'directory-page-main',
        hasRhs: true,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
        tabTitleOption: 'title_course',
      },
    })
    .state('comment-direct-link', {
      url: '/discussions/topics/{topicId:int}/posts/{postId:int}/comments/{commentId:int}',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'discussions/templates/light-header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'discussions/templates/nv-discussion-sort-filter-header.html',
          controller: 'DiscussionSortFilterCtrl as vm',
        },
        main: {
          templateUrl: 'discussions/templates/discussions-index-main.html',
          controller: 'DirectLinkCtrl as vm',
        },
        'desktop-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
        'mobile-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
      },
      resolve: {
        initDiscussions(requestCourse, CurrentCourseManager, CurrentUserManager, DiscussionsManager, $state, $stateParams, _) {
          const course = requestCourse;
          if (!DiscussionsManager.catalogId
              || DiscussionsManager.catalogId !== $stateParams.catalogId
              || DiscussionsManager.context !== 'directLink') {
            DiscussionsManager.initialize({ catalogId: $stateParams.catalogId, context: 'directLink' });
          }

          DiscussionsManager.setDirectLinkHighlight(_.pick($stateParams, 'commentId', 'replyId'));
          DiscussionsManager.initializePusherEvents(course?.id);

          DiscussionsManager.getAllTopics($stateParams.topicId).then(() => {
            DiscussionsManager.setSortOrder($stateParams.sortOrder);
            DiscussionsManager.setFilterOption($stateParams.filterOption);
            DiscussionsManager.loadSinglePost($stateParams.postId).then(
              (post) => {
                DiscussionsManager.setPostData([post]);
                DiscussionsManager.loading = false;

                // if in a topic filter, mark as read here so that a page refresh will not show the "new" badges
                const selectedTopic = DiscussionsManager.findTopic($stateParams.topicId);
                if ($stateParams.topicId > 0
                      && ((!CurrentUserManager.isAdmin() && selectedTopic.getNumNewReleasedPosts() > 0)
                          || (CurrentUserManager.isAdmin() && selectedTopic.getNumNewPosts() > 0))) {
                  // mark those posts as read on the backend, but don't update the frontend yet
                  selectedTopic.markAsRead();
                }

                CurrentCourseManager.course.getTeachingTeamMembers();
                DiscussionsManager.initializeMentions();
              },
              (error) => {
                if (error.status === 404) {
                  $state.go('discussions-index', { topicId: $stateParams.topicId });
                }
              },
            );
          });
          DiscussionsManager.resetData();
        },
      },
      data: {
        level: 1,
        headerClass: 'light-header fixed-header directory-page-header discussions-header',
        mainClass: 'directory-page-main',
        hasRhs: true,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
      },
    })
    .state('reply-direct-link', {
      url: '/discussions/topics/{topicId:int}/posts/{postId:int}/comments/{commentId:int}/replies/{replyId:int}',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'discussions/templates/light-header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'discussions/templates/nv-discussion-sort-filter-header.html',
          controller: 'DiscussionSortFilterCtrl as vm',
        },
        main: {
          templateUrl: 'discussions/templates/discussions-index-main.html',
          controller: 'DirectLinkCtrl as vm',
        },
        'desktop-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
        'mobile-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
      },
      resolve: {
        initDiscussions(requestCourse, CurrentCourseManager, CurrentUserManager, DiscussionsManager, $state, $stateParams, _) {
          const course = requestCourse;
          if (!DiscussionsManager.catalogId
              || DiscussionsManager.catalogId !== $stateParams.catalogId
              || DiscussionsManager.context !== 'directLink') {
            DiscussionsManager.initialize({ catalogId: $stateParams.catalogId, context: 'directLink' });
          }

          DiscussionsManager.setDirectLinkHighlight(_.pick($stateParams, 'commentId', 'replyId'));
          DiscussionsManager.initializePusherEvents(course?.id);

          DiscussionsManager.getAllTopics($stateParams.topicId).then(() => {
            DiscussionsManager.setSortOrder($stateParams.sortOrder);
            DiscussionsManager.setFilterOption($stateParams.filterOption);
            DiscussionsManager.loadSinglePost($stateParams.postId).then(
              (post) => {
                DiscussionsManager.setPostData([post]);
                DiscussionsManager.loading = false;

                // if in a topic filter, mark as read here so that a page refresh will not show the "new" badges
                const selectedTopic = DiscussionsManager.findTopic($stateParams.topicId);
                if ($stateParams.topicId > 0
                      && ((!CurrentUserManager.isAdmin() && selectedTopic.getNumNewReleasedPosts() > 0)
                          || (CurrentUserManager.isAdmin() && selectedTopic.getNumNewPosts() > 0))) {
                  // mark those posts as read on the backend, but don't update the frontend yet
                  selectedTopic.markAsRead();
                }

                CurrentCourseManager.course.getTeachingTeamMembers();
                DiscussionsManager.initializeMentions();
              },
              (error) => {
                if (error.status === 404) {
                  $state.go('discussions-index', { topicId: $stateParams.topicId });
                }
              },
            );
          });
          DiscussionsManager.resetData();
        },
      },
      data: {
        level: 1,
        headerClass: 'light-header fixed-header directory-page-header discussions-header',
        mainClass: 'directory-page-main',
        hasRhs: true,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
      },
    })
    .state('learner-directory', {
      url: '/learner-directory?learnerFilter',
      params: {
        learnerFilter: null,
      },
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'learner_directory/templates/light-header.html',
          controller: 'LearnerDirectorySortFilterController as vm',
        },
        'extra-top-nav-content': {
          templateUrl: 'learner_directory/templates/learner-directory-sort-filter-header.html',
          controller: 'LearnerDirectorySortFilterController as vm',
        },
        main: {
          // template: '<div class="row bg-gray-7"></div>'
          templateUrl: 'learner_directory/templates/learner-directory-main.html',
          controller: 'LearnerDirectoryMainController as vm',
        },
      },
      data: {
        titleKey: 'LEARNER_DIRECTORY.HEADER',
        level: 1,
        headerClass: 'light-header fixed-header directory-page-header',
        mainClass: 'directory-page-main learner-directory',
        hasRhs: false,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
        tabTitleOption: 'title_course',
      },
    })
    .state('teaching-team-directory', {
      url: '/teaching-team-directory',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'teaching_team_directory/templates/teaching-team-directory-course-header.html',
          controller: 'TeachingTeamDirectorySortFilterController as vm',
        },
        'extra-top-nav-content': {
          templateUrl: 'teaching_team_directory/templates/teaching-team-directory-sort-filter-header.html',
          controller: 'TeachingTeamDirectorySortFilterController as vm',
        },
        main: {
          // template: '<div class="row bg-gray-7"></div>'
          templateUrl: 'learner_directory/templates/learner-directory-main.html',
          controller: 'TeachingTeamDirectoryMainController as vm',
        },
      },
      data: {
        titleKey: 'TEACHING_TEAM_DIRECTORY.FILTER_ALL_TEACHING_TEAM',
        level: 1,
        headerClass: 'light-header fixed-header directory-page-header',
        mainClass: 'directory-page-main learner-directory',
        hasRhs: false,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
      },
    })
    .state('team-directory', {
      url: '/team-directory?teamFilter',
      params: {
        teamFilter: null,
      },
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'team_directory/templates/light-header.html',
          controller: 'TeamDirectorySortFilterController as vm',
        },
        'extra-top-nav-content': {
          templateUrl: 'directories/templates/sort-filters.html',
          controller: 'TeamDirectorySortFilterController as vm',
        },
        main: {
          templateUrl: 'team_directory/templates/team-directory-main.html',
          controller: 'TeamDirectoryMainController as vm',
        },
      },
      data: {
        titleKey: 'TEAM_DIRECTORY.HEADER',
        level: 1,
        headerClass: 'light-header fixed-header directory-page-header',
        mainClass: 'directory-page-main team-directory',
        hasRhs: false,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
        tabTitleOption: 'title_course',
      },
    })
    .state('group-directory', {
      url: '/group-directory?groupFilter',
      params: {
        groupFilter: null,
      },
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'group_directory/templates/light-header.html',
          controller: 'GroupDirectorySortFilterController as vm',
        },
        'extra-top-nav-content': {
          templateUrl: 'directories/templates/sort-filters.html',
          controller: 'GroupDirectorySortFilterController as vm',
        },
        main: {
          templateUrl: 'group_directory/templates/group-directory-main.html',
          controller: 'GroupDirectoryMainController as vm',
        },
      },
      data: {
        titleKey: 'GROUP_DIRECTORY.HEADER',
        level: 1,
        headerClass: 'light-header fixed-header directory-page-header',
        mainClass: 'directory-page-main group-directory',
        hasRhs: false,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
      },
    })
    .state('learner-profile-modal', {
      parent: 'modal-page',
      url: '/courses/:catalogId/users/{userId:int}?galleryMode',
      views: {
        'modal-page-header@modal-page': {
          templateUrl: 'learner_profiles/templates/profile-modal-header.html',
          controller: 'LearnerProfileBaseCtrl as vm',
        },
        'modal-content@modal-page': {
          templateUrl: 'learner_profiles/templates/learner-profile-main.html',
          controller: 'LearnerProfileCtrl as vm',
        },
        'modal-pagination@modal-page': {
          templateUrl: 'layouts/templates/nv-modal-page-pagination-view.html',
          controller: 'LearnerProfilePaginationCtrl as vm',
        },
      },
      data: {
        titleKey: 'PROFILE.HEADER',
        mainClass: 'profile learner-profile',
        level: 4,
        tabTitleOption: 'title_institution',
      },
    })
    .state('learner-profile-page', {
      parent: 'course-lightbox',
      url: '/courses/:catalogId/users/{userId:int}?galleryMode',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'learner_profiles/templates/learner-profile-header.html',
          controller: 'LearnerProfileBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'learner_profiles/templates/learner-profile-main.html',
          controller: 'LearnerProfileCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'learner_profiles/templates/profile-lightbox-header.html',
          controller: 'LearnerProfileBaseCtrl as vm',
        },
      },
      data: {
        titleKey: 'PROFILE.HEADER',
        level: 3,
        mainClass: 'profile learner-profile',
        tabTitleOption: 'title_institution',

      },
    })
    .state('team-profile-modal', {
      parent: 'modal-page',
      url: '/courses/:catalogId/teams/{teamId:int}?galleryMode',
      params: {
        contact: false,
      },
      views: {
        'modal-page-header@modal-page': {
          templateUrl: 'teams/templates/profile/lightbox-header.html',
          controller: 'TeamProfileBaseCtrl as vm',
        },
        'modal-content@modal-page': {
          templateUrl: 'teams/templates/team-profile.html',
          controller: 'TeamProfileCtrl as vm',
        },
        'modal-pagination@modal-page': {
          templateUrl: 'layouts/templates/nv-modal-page-pagination-view.html',
          controller: 'TeamProfilePaginationCtrl as vm',
        },
      },
      data: {
        mainClass: 'profile team-profile',
        level: 4,
      },
    })
    .state('team-profile-page', {
      parent: 'course-lightbox',
      url: '/teams/{teamId:int}',
      params: { forceLoad: false },
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'teams/templates/profile/team-profile-header.html',
          controller: 'TeamProfileBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'teams/templates/team-profile.html',
          controller: 'TeamProfileCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'teams/templates/profile/lightbox-header.html',
          controller: 'TeamProfileBaseCtrl as vm',
        },
      },
      data: {
        mainClass: 'profile team-profile',
        level: 3,
      },
    })
    .state('points-breakdown-page', {
      url: '/users/:userId/points-breakdown',
      parent: 'course-lightbox',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'timelines/templates/points-breakdown-header.html',
          controller: 'TimelineBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'timelines/templates/points-breakdown-timeline.html',
          controller: 'TimelineShowCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'timelines/templates/points-breakdown-sticky-header.html',
          controller: 'TimelineBaseCtrl as vm',
        },
      },
      data: {
        titleKey: 'POINTS.HEADER',
        level: 3,
        headerCollapse: true,
        mainClass: 'points-breakdown course-home main new',
      },
    })
    .state('evaluation-new', {
      url: '/exercises/{exerciseId:int}/peer-reviews/new?{reportId:int}&{mentorDashboard:bool}',
      parent: 'course-lightbox',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'EvaluationsFormBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-form.html',
          controller: 'EvaluationsFormCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-form-lightbox-header.html',
          controller: 'EvaluationsFormCtrl as vm',
        },
      },
      resolve: {
        requestPeerReview(
          $stateParams,
          EvaluationsTaskManager,
          CurrentCourseManager,
          requestCourse,
          checkCoursePermission,
          $state,
        ) {
          if (requestCourse.inArchiveMode) {
            $state.go('course-home', { catalogId: $stateParams.catalogId });
          }
          return EvaluationsTaskManager.getNewPeerReview(requestCourse.catalogId, $stateParams.exerciseId, $stateParams.reportId)
            .then(
              (response) => response,
              (response) => {
                $state.go('course-home', { catalogId: $stateParams.catalogId });
              },
            );
        },
      },
      data: {
        headerCollapse: true,
        headerClass: 'dark-header',
        // learner-exercise is needed for the lightbox
        mainClass: 'evaluations form',
        titleKey: 'EVALUATIONS.START_HEADER',
        level: 3,
      },
    })
    .state('evaluation-edit', {
      url: '/exercises/{exerciseId:int}/peer-reviews/{peerReviewId:int}/edit',
      params: {
        reportId: null,
      },
      parent: 'course-lightbox',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'EvaluationsFormBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-form.html',
          controller: 'EvaluationsFormCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-form-lightbox-header.html',
          controller: 'EvaluationsFormCtrl as vm',
        },
      },
      resolve: {
        requestPeerReview(
          $stateParams,
          EvaluationsTaskManager,
          CurrentCourseManager,
          requestCourse,
          checkCoursePermission,
          $state,
          $q,
          CurrentUserManager,
        ) {
          if (requestCourse.inArchiveMode) {
            $state.go('course-home', { catalogId: $stateParams.catalogId });
          }

          const deferred = $q.defer();

          EvaluationsTaskManager.getPeerReview(requestCourse.catalogId, $stateParams.peerReviewId)
            .then(
              (peerReview) => {
                if (peerReview.customQuestions.requirementApplicable && !peerReview.customQuestions.learnerEvaluationsInProgress) {
                  $state.go('course-home', { catalogId: $stateParams.catalogId });
                  deferred.reject();
                } else {
                  deferred.resolve(peerReview);
                }
              },
              (response) => {
                if (CurrentUserManager.hasLoggedIn()) {
                  $state.go('course-home', { catalogId: $stateParams.catalogId });
                } else {
                  $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
                }
                deferred.reject();
              },
            );

          return deferred.promise;
        },
      },
      data: {
        headerCollapse: true,
        headerClass: 'dark-header',
        // learner-exercise is needed for the lightbox
        mainClass: 'evaluations form',
        titleKey: 'EVALUATIONS.START_HEADER',
        level: 3,
      },
    })
    .state('evaluation-report-page', {
      parent: 'course-lightbox',
      url: '/courses/:catalogId/peer-reviews/{peerReviewId:int}/report',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'exercises/templates/learner-exercise-header.html',
          controller: 'EvaluationsFormCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-report.html',
          controller: 'EvaluationsFormCtrl as vm',
        },
      },
      resolve: {
        requestPeerReview(
          $stateParams,
          EvaluationsTaskManager,
          CurrentCourseManager,
          requestCourse,
          checkCoursePermission,
          $state,
          CurrentUserManager,
        ) {
          return EvaluationsTaskManager.getPeerReview(requestCourse.catalogId, $stateParams.peerReviewId)
            .then(
              (response) => response,
              (response) => {
                if (CurrentUserManager.hasLoggedIn()) {
                  $state.go('course-home', { catalogId: $stateParams.catalogId });
                } else {
                  $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
                }
              },
            );
        },
      },
      data: {
        headerExpand: true,
        mainClass: 'evaluations results',
        headerClass: 'exercise-header',
        level: 3,
      },
    })
    .state('evaluation-report-modal', {
      parent: 'modal-page',
      url: '/courses/:catalogId/peer-reviews/{peerReviewId:int}/report',
      params: {
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'evaluations/templates/evaluation-report.html',
          controller: 'EvaluationsFormCtrl as vm',
        },
      },
      resolve: {
        requestPeerReview(
          $stateParams,
          EvaluationsTaskManager,
          CurrentCourseManager,
          $state,
          CurrentUserManager,
        ) {
          return EvaluationsTaskManager.getPeerReview($stateParams.catalogId, $stateParams.peerReviewId)
            .then(
              (response) => response,
              (response) => {
                if (CurrentUserManager.hasLoggedIn()) {
                  $state.go('course-home', { catalogId: $stateParams.catalogId });
                } else {
                  $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
                }
              },
            );
        },
      },
      data: {
        headerExpand: true,
        titleKey: 'EXERCISES.VIEW_SUBMISSION',
        mainClass: 'evaluations results',
        level: 4,
      },
    })
    .state('evaluation-show-page', {
      parent: 'course-lightbox',
      url: '/courses/:catalogId/peer-reviews/{peerReviewId:int}/show',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'EvaluationsFormBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-show.html',
          controller: 'EvaluationsFormCtrl as vm',
        },
      },
      resolve: {
        requestPeerReview(
          $stateParams,
          EvaluationsTaskManager,
          CurrentCourseManager,
          requestCourse,
          checkCoursePermission,
          $state,
          CurrentUserManager,
        ) {
          return EvaluationsTaskManager.getPeerReview(requestCourse.catalogId, $stateParams.peerReviewId)
            .then(
              (response) => response,
              (response) => {
                if (CurrentUserManager.hasLoggedIn()) {
                  $state.go('course-home', { catalogId: $stateParams.catalogId });
                } else {
                  $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
                }
              },
            );
        },
      },
      data: {
        headerExpand: true,
        mainClass: 'evaluations results',
        headerClass: 'exercise-header',
        level: 3,
      },
    })
    .state('evaluation-show-modal', {
      parent: 'modal-page',
      url: '/courses/:catalogId/peer-reviews/{peerReviewId:int}/show',
      params: {
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'evaluations/templates/evaluation-show.html',
          controller: 'EvaluationsFormCtrl as vm',
        },
      },
      resolve: {
        requestPeerReview(
          $stateParams,
          EvaluationsTaskManager,
          CurrentCourseManager,
          $state,
          CurrentUserManager,
        ) {
          return EvaluationsTaskManager.getPeerReview($stateParams.catalogId, $stateParams.peerReviewId)
            .then(
              (response) => response,
              (response) => {
                if (CurrentUserManager.hasLoggedIn()) {
                  $state.go('course-home', { catalogId: $stateParams.catalogId });
                } else {
                  $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
                }
              },
            );
        },
      },
      data: {
        headerExpand: true,
        titleKey: 'EXERCISES.VIEW_SUBMISSION',
        mainClass: 'evaluations results',
        level: 4,
      },
    })
    .state('evaluation-results-page', {
      parent: 'course-lightbox',
      url: '/courses/:catalogId/reports/{reportId:int}/evaluation-results',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-results.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-feedback-lightbox-header.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
      },
      resolve: {
        requestPeerReviews(
          $stateParams,
          EvaluationsTaskManager,
          $state,
          CurrentUserManager,
        ) {
          return EvaluationsTaskManager.getAggregatePeerReviews($stateParams.catalogId, $stateParams.reportId)
            .then(
              (response) => response,
              (response) => {
                if (CurrentUserManager.hasLoggedIn()) {
                  $state.go('course-home', { catalogId: $stateParams.catalogId });
                } else {
                  $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
                }
              },
            );
        },
      },
      data: {
        mainClass: 'evaluations results',
        headerClass: 'exercise-header',
        level: 3,
      },
    })
    .state('evaluation-results-modal', {
      parent: 'modal-page',
      url: '/courses/:catalogId/reports/{reportId:int}/evaluation-results',
      params: {
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'evaluations/templates/evaluation-results.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
        'modal-page-header@modal-page': {
          templateUrl: 'evaluations/templates/evaluation-feedback-lightbox-header.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
      },
      resolve: {
        requestPeerReviews(
          $stateParams,
          EvaluationsTaskManager,
          $state,
          CurrentUserManager,
        ) {
          return EvaluationsTaskManager.getAggregatePeerReviews($stateParams.catalogId, $stateParams.reportId)
            .then(
              (response) => response,
              (response) => {
                if (CurrentUserManager.hasLoggedIn()) {
                  $state.go('course-home', { catalogId: $stateParams.catalogId });
                } else {
                  $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
                }
              },
            );
        },
      },
      data: {
        titleKey: 'EXERCISES.VIEW_SUBMISSION',
        mainClass: 'evaluations results',
        level: 4,
      },
    })
    .state('evaluation-detailed-results-page', {
      parent: 'evaluation-results-page',
      url: '/detailed',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-results.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-feedback-lightbox-header.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
      },
      resolve: {
        setPeerReview(
          $stateParams,
          requestPeerReviews,
          EvaluationsTaskManager,
          $state,
        ) {
          return EvaluationsTaskManager.setCurrentPeerReview($stateParams.peerReviewId);
        },
      },
      data: {
        mainClass: 'evaluations results',
        headerClass: 'exercise-header',
        level: 3,
      },
    })
    .state('evaluation-detailed-results-modal', {
      parent: 'evaluation-results-modal',
      url: '/detailed',
      params: {
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'evaluations/templates/evaluation-results.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
        'modal-page-header@modal-page': {
          templateUrl: 'evaluations/templates/evaluation-feedback-lightbox-header.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
      },
      resolve: {
        setPeerReview(
          $stateParams,
          requestPeerReviews,
          EvaluationsTaskManager,
          $state,
        ) {
          return EvaluationsTaskManager.setCurrentPeerReview($stateParams.peerReviewId);
        },
      },
      data: {
        titleKey: 'EXERCISES.VIEW_SUBMISSION',
        mainClass: 'evaluations results',
        level: 4,
      },
    })
    .state('evaluation-result-page', {
      parent: 'evaluation-results-page',
      url: '/{peerReviewId:int}',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-result.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
        'lightbox-header@course-lightbox': {
          templateUrl: 'evaluations/templates/evaluation-feedback-lightbox-header.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
      },
      resolve: {
        setPeerReview(
          requestPeerReviews,
          $stateParams,
          EvaluationsTaskManager,
          $state,
        ) {
          return EvaluationsTaskManager.setCurrentPeerReview($stateParams.peerReviewId);
        },
      },
      data: {
        mainClass: 'evaluations result',
        headerClass: 'exercise-header',
        level: 3,
      },
    })
    .state('evaluation-result-modal', {
      parent: 'evaluation-results-modal',
      url: '/{peerReviewId:int}',
      params: {
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'evaluations/templates/evaluation-result.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
        'modal-page-header@modal-page': {
          templateUrl: 'evaluations/templates/evaluation-feedback-lightbox-header.html',
          controller: 'PeerReviewsBaseCtrl as vm',
        },
      },
      resolve: {
        setPeerReview(
          requestPeerReviews,
          $stateParams,
          EvaluationsTaskManager,
          $state,
        ) {
          return EvaluationsTaskManager.setCurrentPeerReview($stateParams.peerReviewId);
        },
      },
      data: {
        titleKey: 'EXERCISES.VIEW_SUBMISSION',
        mainClass: 'evaluations result',
        level: 4,
      },
    })
    .state('team-workspace', {
      url: '/courses/:catalogId/teams/{teamId:int}/workspace?googleDriveOpen',
      parent: 'flyout-panel',
      views: {
        'flyout-content@flyout-panel': {
          templateUrl: 'team_workspace/templates/team-workspace-main.html',
          controller: 'TeamWorkspaceMainController as vm',
        },
        'flyout-page-header@flyout-panel': {
          templateUrl: 'team_workspace/templates/team-workspace-header.html',
          controller: 'TeamWorkspaceMainController as vm',
        },
      },
      resolve: {
        requestTeam(
          $stateParams,
          $state,
          TeamWorkspaceManager,
          CurrentUserManager,
          CurrentPermissionsManager,
          CurrentCourseManager,
          ConfirmationOverlays,
          $q,
        ) {
          const requestTeamPromise = TeamWorkspaceManager.requestTeam($stateParams.catalogId, $stateParams.teamId);
          $q.all([CurrentCourseManager.requestCoursePromise, requestTeamPromise])
            .then((response) => {
              const isValidAdmin = CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isTeachingAssistant();
              if (!isValidAdmin && (!TeamWorkspaceManager.currentTeam.currentAccountTeamMember || !TeamWorkspaceManager.currentTeam.currentAccountTeamMember.approved)) {
                ConfirmationOverlays.openConfirmationModal(
                  'team_workspace/templates/team-workspace-permissions-error-overlay.html',
                  'AttachModalResolvesToVmCtrl as vm',
                  {
                    vmResolves() {
                      return {
                        course: CurrentCourseManager.course,
                        currentTeam: TeamWorkspaceManager.currentTeam,
                      };
                    },
                  },
                ).result.then(() => {
                  $state.go('team-profile-page', { catalogId: $stateParams.catalogId, teamId: $stateParams.teamId, mobileapp: $stateParams.mobileapp });
                });
                return $q.reject();
              }
              return null;
            }, () => {
              if (CurrentUserManager.hasLoggedIn()) {
                $state.go('course-home', { catalogId: $stateParams.catalogId });
              } else {
                $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
              }
            });
        },
      },
      data: {
        level: 3.5,
        params: {
          googleDriveOpen: null,
        },
      },
    })
    .state('team-workspace-full', {
      url: '/teams/{teamId:int}/workspace?googleDriveOpen',
      parent: 'course-mainGridContent',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'team_workspace/templates/team-workspace-course-header.html',
          controller: 'TeamWorkspaceBaseController as vm',
        },
        main: {
          templateUrl: 'team_workspace/templates/team-workspace-main-full.html',
          controller: 'TeamWorkspaceMainController as vm',
        },
      },
      resolve: {
        requestTeam(
          $stateParams,
          $state,
          TeamWorkspaceManager,
          CurrentUserManager,
          CurrentPermissionsManager,
          CurrentCourseManager,
          ConfirmationOverlays,
          $q,
        ) {
          const requestTeamPromise = TeamWorkspaceManager.requestTeam($stateParams.catalogId, $stateParams.teamId);
          $q.all([CurrentCourseManager.requestCoursePromise, requestTeamPromise])
            .then((response) => {
              const isValidAdmin = CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isTeachingAssistant();
              if (!isValidAdmin && (!TeamWorkspaceManager.currentTeam.currentAccountTeamMember || !TeamWorkspaceManager.currentTeam.currentAccountTeamMember.approved)) {
                ConfirmationOverlays.openConfirmationModal(
                  'team_workspace/templates/team-workspace-permissions-error-overlay.html',
                  'AttachModalResolvesToVmCtrl as vm',
                  {
                    vmResolves() {
                      return {
                        course: CurrentCourseManager.course,
                        currentTeam: TeamWorkspaceManager.currentTeam,
                      };
                    },
                  },
                ).result.then(() => {
                  $state.go('team-profile-page', { catalogId: $stateParams.catalogId, teamId: $stateParams.teamId, mobileapp: $stateParams.mobileapp });
                });
                return $q.reject();
              }
              return null;
            }, () => {
              if (CurrentUserManager.hasLoggedIn()) {
                $state.go('course-home', { catalogId: $stateParams.catalogId });
              } else {
                $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
              }
            });
        },
      },
      onEnter(
        TeamWorkspaceManager,
      ) {
        TeamWorkspaceManager.isTeamWorkspaceFull = true;
      },
      onExit(
        TeamWorkspaceManager,
      ) {
        TeamWorkspaceManager.isTeamWorkspaceFull = false;
      },
      data: {
        mainClass: 'team-workspace-main-full',
        isDirectoryPage: true,
        level: 1,
        params: {
          googleDriveOpen: null,
        },
      },
    })
    .state('team-workspace-members-page', {
      parent: 'course-lightbox',
      url: '/courses/:catalogId/teams/{teamId:int}/workspace/members',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'shared/templates/l3-header-text.html',
          controller: 'TeamWorkspaceMembersBaseController as vm',
        },
        'lightbox-main@course-lightbox': {
          templateUrl: 'team_workspace/templates/team-workspace-members.html',
          controller: 'TeamWorkspaceMembersController as vm',
        },
      },
      resolve: {
        requestTeam(
          $stateParams,
          $state,
          requestCourse,
          checkCoursePermission,
          TeamWorkspaceManager,
          CurrentUserManager,
        ) {
          return TeamWorkspaceManager.requestTeamWithAllMembers($stateParams.catalogId, $stateParams.teamId)
            .then((team) => {
              if (!team.currentAccountTeamMember || !team.currentAccountTeamMember.approved) {
                $state.go('team-profile-modal', { catalogId: $stateParams.catalogId, teamId: $stateParams.teamId });
              }
            }, () => {
              if (CurrentUserManager.hasLoggedIn()) {
                $state.go('course-home', { catalogId: $stateParams.catalogId });
              } else {
                $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
              }
            });
        },
      },
      data: {
        mainClass: 'team-workspace-main members-only',
        level: 3,
      },
    })
    .state('team-workspace-members-modal', {
      parent: 'modal-page',
      url: '/courses/:catalogId/teams/{teamId:int}/workspace/members',
      params: {
      },
      views: {
        'modal-content@modal-page': {
          templateUrl: 'team_workspace/templates/team-workspace-members.html',
          controller: 'TeamWorkspaceMembersController as vm',
        },
      },
      resolve: {
        requestTeam(
          $state,
          $stateParams,
          CurrentPermissionsManager,
          CurrentUserManager,
          TeamWorkspaceManager,
        ) {
          return TeamWorkspaceManager.requestTeamWithAllMembers($stateParams.catalogId, $stateParams.teamId)
            .then((team) => {
              if ((!team.currentAccountTeamMember || !team.currentAccountTeamMember.approved) || CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isTeachingAssistant()) {
                $state.go('team-profile-modal', { catalogId: $stateParams.catalogId, teamId: $stateParams.teamId });
              }
            }, () => {
              if (CurrentUserManager.hasLoggedIn()) {
                $state.go('course-home', { catalogId: $stateParams.catalogId });
              } else {
                $state.go('users.authentications.sign-in', { catalogId: $stateParams.catalogId });
              }
            });
        },
      },
      data: {
        headerExpand: true,
        titleKey: 'EXERCISES.VIEW_SUBMISSION',
        mainClass: 'team-workspace-main members-only',
        level: 4,
      },
    })
    .state('team-workspace-post-direct-link', {
      url: '/posts/{postId:int}',
      parent: 'team-workspace',
      views: {
        'flyout-page-header@flyout-panel': {
          templateUrl: 'team_workspace/templates/team-workspace-header.html',
          controller: 'TeamWorkspaceBaseController as vm',
        },
        'flyout-content@flyout-panel': {
          templateUrl: 'team_workspace/templates/team-workspace-main.html',
          controller: 'TeamWorkspaceMainController as vm',
        },
      },
      data: {
        level: 3.5,
      },
    })
    .state('team-workspace-post-direct-link-full', {
      url: '/posts/{postId:int}',
      parent: 'team-workspace-full',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'team_workspace/templates/team-workspace-course-header.html',
          controller: 'TeamWorkspaceBaseController as vm',
        },
        'main@course-mainGridContent': {
          templateUrl: 'team_workspace/templates/team-workspace-main-full.html',
          controller: 'TeamWorkspaceMainController as vm',
        },
      },
      data: {
        mainClass: 'team-workspace-main-full',
        isDirectoryPage: true,
        level: 1,
      },
    })
    .state('team-workspace-comment-direct-link', {
      url: '/posts/{postId:int}/comments/{commentId:int}',
      parent: 'team-workspace',
      views: {
        'flyout-page-header@flyout-panel': {
          templateUrl: 'team_workspace/templates/team-workspace-header.html',
          controller: 'TeamWorkspaceBaseController as vm',
        },
        'flyout-content@flyout-panel': {
          templateUrl: 'team_workspace/templates/team-workspace-main.html',
          controller: 'TeamWorkspaceMainController as vm',
        },
      },
      data: {
        level: 3.5,
      },
    })
    .state('team-workspace-comment-direct-link-full', {
      url: '/posts/{postId:int}/comments/{commentId:int}',
      parent: 'team-workspace-full',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'team_workspace/templates/team-workspace-course-header.html',
          controller: 'TeamWorkspaceBaseController as vm',
        },
        'main@course-mainGridContent': {
          templateUrl: 'team_workspace/templates/team-workspace-main-full.html',
          controller: 'TeamWorkspaceMainController as vm',
        },
      },
      data: {
        mainClass: 'team-workspace-main-full',
        isDirectoryPage: true,
        level: 1,
      },
    })
    .state('team-workspace-reply-direct-link', {
      url: '/posts/{postId:int}/comments/{commentId:int}/replies/{replyId:int}',
      parent: 'team-workspace',
      views: {
        'flyout-page-header@flyout-panel': {
          templateUrl: 'team_workspace/templates/team-workspace-header.html',
          controller: 'TeamWorkspaceBaseController as vm',
        },
        'flyout-content@flyout-panel': {
          templateUrl: 'team_workspace/templates/team-workspace-main.html',
          controller: 'TeamWorkspaceMainController as vm',
        },
      },
      data: {
        level: 3.5,
      },
    })
    .state('team-workspace-reply-direct-link-full', {
      url: '/posts/{postId:int}/comments/{commentId:int}/replies/{replyId:int}',
      parent: 'team-workspace-full',
      views: {
        'top-header@course-mainGridContent': {
          templateUrl: 'team_workspace/templates/team-workspace-course-header.html',
          controller: 'TeamWorkspaceBaseController as vm',
        },
        'main@course-mainGridContent': {
          templateUrl: 'team_workspace/templates/team-workspace-main-full.html',
          controller: 'TeamWorkspaceMainController as vm',
        },
      },
      data: {
        mainClass: 'team-workspace-main-full',
        isDirectoryPage: true,
        level: 1,
      },
    })
    .state('program-home', {
      parent: 'course-mainGridContent',
      url: '/discussions?{topicId:int}&filterOption&sortOrder',
      views: {
        'top-header': {
          templateUrl: 'course_home/templates/header.html',
        },
        'extra-top-nav-content': {
          templateUrl: 'course_home/templates/program-home-top-nav-content.html',
          controller: 'CourseHomeBaseCtrl as vm',
        },
        main: {
          templateUrl: 'discussions/templates/discussions-index-main.html',
          controller: 'ProgramHomeController as vm',
        },
        'desktop-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
        'mobile-right@user-grid-abstract': {
          templateUrl: 'discussions/templates/nv-rhs-discussions-index.html',
          controller: 'DiscussionsRhsCtrl as vm',
        },
      },
      data: {
        titleKey: 'COURSE_HOME.HEADER.PROGRAM_NONALIAS_HOME',
        level: 1,
        headerClass: 'course_home directory-page-header program_home',
        mainClass: 'program_home directory-page-main',
        leftNavState: 'expanded',
        hasRhs: true,
        hasInstitutionBackgroundHeader: true,
        mainContent: {
          flex: true,
          headerNoPadding: true,
        },
      },
    })
    .state('mentees', {
      url: '/mentees/{ownerType}/{ownerId:int}?filter&sort&sortOrder&exerciseId',
      parent: 'course-mainGridContent',
      reloadOnSearch: false,
      params: {
        filter: null,
        sort: null,
        sortOrder: null,
        exerciseId: null,
      },
      views: {
        'top-header': {
          templateUrl: 'mentors/templates/mentees-top-header.html',
          controller: 'MenteesPageHeaderController as vm',
        },
        'extra-top-nav-content': {
          templateUrl: 'mentors/templates/mentees-sort-filter-header.html',
          controller: 'MenteesPageBaseController as menteesBaseCtrl',
        },
        main: {
          templateUrl: 'mentors/templates/mentees-main.html',
          controller: 'MenteesPageController as menteesMainCtrl',
        },
      },
      resolve: {
        checkIsMentor(CurrentPermissionsManager, $q, $state, $stateParams, requestCourse) {
          if ($stateParams.ownerType === 'User' && !CurrentPermissionsManager.isMentor()) {
            $state.go('dashboard');
            return $q.reject();
          }

          return null;
        },
      },
      data: {
        level: 1,
        headerClass: 'light-header fixed-header directory-page-header mentees-header',
        mainClass: 'directory-page-main mentees-main',
        hasRhs: false,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        mainContent: {
          flex: true,
        },
      },
    })
    .state('/framed-froala', {
      url: '/framed-froala?textId&codeView&inline&uploadType&id&placeholder&disabled&preset&apptype&platform_language',
      templateUrl: 'froala/templates/framed-froala.html',
      controller: 'FramedFroalaCtrl as vm',
      data: {
        level: 3,
      },
      resolve: {
        translateReady($stateParams, $translate) {
          if ($stateParams.platform_language) {
            return $translate.use($stateParams.platform_language);
          }
          return $translate.onReady();
        },
      },
    })
    .state('framed-froala-success', {
      url: '/success?{textId:int}&{pristine:bool}',
      params: {
        pristine: {
          value: false,
          squash: true,
        },
      },
      data: {
        level: 3,
      },
    })
    .state('framed-froala-failure', {
      url: '/failure?textId',
      data: {
        level: 3,
      },
    })
    .state('account-settings', {
      parent: 'mainGridContent',
      url: '/account_settings',
      abstract: true,
      params: {
        // we do this to bypass the "catalogId" because in onExit of a course, it checks to see if there is a catalogId, and if there is it keeps the current course in CurrentCourseManager
        referenceCatalogId: {
          value: null,
          squash: true,
        },
      },
      views: {
        'top-header': {
          controller: 'AccountSettingsNavCtrl as vm',
          templateUrl: 'account_settings/templates/account-settings-header.html',
        },
        'extra-top-nav-content@mainGridContent': {
          controller: 'AccountSettingsNavCtrl as vm',
          templateUrl: 'account_settings/templates/account-settings-nav.html',
        },
      },
      data: {
        level: 1,
        titleKey: 'ACCOUNT_SETTINGS.ACCOUNT_BASICS_TAB',
        hasInstitutionBackgroundHeader: true,
        mainPanelScrollable: true,
        headerClass: 'settings-extra-header',
        tabTitleOption: 'title_institution',
      },
      onEnter(
        $stateParams,
        CurrentUserManager,
      ) {
        CurrentUserManager.setEmailPreferenceCatalogId($stateParams.referenceCatalogId);
      },
      onExit(
        StateManager,
        CurrentCourseManager,
        CurrentUserManager,
      ) {
        if (!StateManager.paramsForLastStateAttempted.catalogId) {
          CurrentCourseManager.resetCourse();
          CurrentUserManager.resetCurrentUserCourse();
        }
      },
    })
    .state('account-basics', {
      parent: 'account-settings',
      url: '/basics',
      views: {
        'main@mainGridContent': {
          templateUrl: 'account_settings/templates/account-basics.html',
          controller: 'AccountBasicsCtrl as vm',
        },
      },
      data: {
        level: 1,
      },
    })
    .state('email-preferences', {
      parent: 'account-settings',
      url: '/email_preferences',
      views: {
        'main@mainGridContent': {
          templateUrl: 'account_settings/templates/email-preferences.html',
          controller: 'EmailPreferencesCtrl as vm',
        },
      },
      data: {
        level: 1,
      },
      resolve: {
        translateReady($translate) {
          return $translate.onReady();
        },
      },
    })
    .state('language-and-timezone', {
      parent: 'account-settings',
      url: '/language_and_timezone',
      views: {
        'main@mainGridContent': {
          templateUrl: 'account_settings/templates/language-and-timezone.html',
          controller: 'LangsAndTZController as vm',
        },
      },
      data: {
        level: 1,
      },
    })
    .state('user-management', {
      parent: 'course-mainGridContent',
      url: '/user-management?{roleFilter:int}',
      abstract: true,
      params: {
        initialState: false,
        forceReload: false,
      },
      views: {
        'top-header': {
          templateUrl: 'user_management/templates/light-header.html',
          controller: 'UserManagementHeaderController as vm',
        },
        'main@course-mainGridContent': {
          templateUrl: 'user_management/templates/main.html',
          controller: 'UserManagementMainController as vm',
        },
      },
      data: {
        titleKey: 'USER_MANAGEMENT.HEADER',
        level: 1,
        headerClass: 'light-header fixed-header institution-l1-header',
        mainClass: 'user-management-main',
        hasRhs: false,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        titleTranslation: 'USER_MANAGEMENT.HEADER',
      },
      resolve: {
        translateReady($translate) {
          return $translate.onReady();
        },
        checkPermissions: redirectIfUnauthorized((manager) => manager.hasCourseAdminPermissions(), 'course-home'),
      },
    })
    .state('user-management-learners', {
      parent: 'user-management',
      url: '/learners',
      data: {
        userTypeFilter: 1, // Learners
      },
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.isConfigAndRegistrationRole() || manager.isInstructor() || manager.isLearnerRegistrationRole(), 'user-management-course-admins'),
      },
    })
    .state('user-management-course-admins', {
      parent: 'user-management',
      url: '/course-admins',
      data: {
        userTypeFilter: 0, // Admins
      },
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.hasFullCourseAdminPermissions(), 'user-management-learners'),
      },
    })
    .state('user-management-mentors', {
      parent: 'user-management',
      url: '/mentors',
      data: {
        userTypeFilter: 3, // Mentors
      },
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.hasFullCourseAdminPermissions(), 'user-management-learners'),
      },
    })
    .state('user-management-search', {
      parent: 'user-management',
      url: '/search?{query:string}',
      data: {
        userTypeFilter: 2, // All (Search)
      },
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.isConfigAndRegistrationRole() || manager.isInstructor() || manager.isLearnerRegistrationRole(), 'user-management-course-admins'),
      },
    })

    // Team facilitation
    .state('team-facilitation', {
      parent: 'course-mainGridContent',
      url: '/team-facilitation',
      params: {
        initialState: false,
        loadGroupsTab: false,
      },
      views: {
        'top-header': {
          templateUrl: 'team_facilitation/templates/light-header.html',
          controller: 'LightHeaderController as vm',
        },
        'main@course-mainGridContent': {
          templateUrl: 'team_facilitation/templates/team-facilitation.html',
          controller: 'TeamFacilitationController as vm',
        },
      },
      data: {
        titleKey: 'USER_MANAGEMENT.HEADER',
        level: 1,
        headerClass: 'light-header fixed-header institution-l1-header',
        mainClass: 'user-management-main',
        hasRhs: false,
        leftNavState: 'expanded',
        isDirectoryPage: true,
        titleTranslation: 'TEAM_FACILITATION.HEADER',
      },
      resolve: {
        translateReady($translate) {
          return $translate.onReady();
        },
        checkPermissions: redirectIfUnauthorized((manager) => manager.isInstructor() || manager.isTeachingAssistant(), 'course-home'),
      },
    })
    .state('skill-tags', {
      parent: 'institution-dashboard',
      url: 'skill-tags',
      views: {
        'main@mainGridContent': {
          controller: 'SkillTagsCtrl as vm',
          templateUrl: 'skill_tags/templates/skill-tags-dashboard.html',
        },
        'top-header@mainGridContent': {
          template: '<div nv-l1-course-header = ""></div>',
        },
      },
      data: {
        titleKey: 'INSTITUTIONS.SKILL_TAGS.HEADER',
        headerClass: 'light-header fixed-header institution-l1-header',
        mainClass: 'advanced-settings-main user-management-main institution-roles-main',
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasOrgAdminPermissions(), 'institution-dashboard'),
      },
    })
    .state('analytics-dashboard', {
      parent: 'institution-dashboard',
      url: 'analytics-dashboard',
      views: {
        'main@mainGridContent': {
          controller: 'AnalyticsDashboardCtrl as vm',
          templateUrl: 'analytics_dashboard/templates/analytics-dashboard.html',
        },
        'top-header@mainGridContent': {
          template: '<div nv-l1-course-header = ""></div>',
        },
      },
      data: {
        titleKey: 'INSTITUTIONS.ANALYTICS_DASHBOARD.HEADER',
        headerClass: 'light-header fixed-header institution-l1-header',
        mainClass: 'advanced-settings-main analytics-dashboard',
      },
      resolve: {
        // checkPermission: redirectIfUnauthorized((manager) => manager.hasOrgAdminPermissions(), 'institution-dashboard'),
      },
    })
    .state('course-analytics', {
      url: '/analytics',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          template: '<div nv-l1-course-header = ""></div>',
        },
        main: {
          controller: 'AnalyticsDashboardCtrl as vm',
          templateUrl: 'analytics_dashboard/templates/course-analytics-dashboard.html',
        },
      },
      data: {
        titleKey: 'INSTITUTIONS.COURSE_ANALYTICS.HEADER',
        mainClass: 'advanced-settings-main analytics-dashboard',
        headerClass: 'light-header fixed-header directory-page-header',
      },
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.isReportAdmin() || manager.isInstructor(), 'course-home'),
      },
    })
    .state('cohorts', {
      url: '/cohorts',
      parent: 'course-analytics',
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.isReportAdmin() || manager.isInstructor(), 'course-home'),
      },
    })
    .state('skills', {
      url: '/skills',
      parent: 'course-analytics',
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.isReportAdmin() || manager.isInstructor(), 'course-home'),
      },
    })
    .state('activities', {
      url: '/activities',
      parent: 'course-analytics',
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.isReportAdmin() || manager.isInstructor(), 'course-home'),
      },
    })
    .state('quiz-survey', {
      url: '/quiz-and-survey/{questionId:int}',
      parent: 'course-analytics',
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.isReportAdmin() || manager.isInstructor(), 'course-home'),
      },
    })
    .state('content-library-collections-parent', {
      abstract: true,
      url: '/content-library/collections',
      parent: 'mainGridContent',
      views: {
        main: {
          controller: 'ContentLibraryCtrl as vm',
          templateUrl: 'content-library/templates/collection-routes-react-app.html',
        },
      },
      data: {
        level: 1,
        headerClass: 'no-header',
        hasRhs: false,
      },
    })
    .state('content-library-collections', {
      parent: 'content-library-collections-parent',
      url: '/',
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasCollectionAccess(), 'dashboard'),
      },
    })
    .state('content-library-collection-home', {
      parent: 'content-library-collections-parent',
      url: '/{collectionId:int}/:catalogId/home?{folderId:int}&{lessonId:int}',
      onExit(CurrentCourseManager) {
        CurrentCourseManager.resetCourse();
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasCollectionViewerPermissions(), 'content-library-collections'),
      },
    })
    .state('content-library-collection-user-management', {
      parent: 'content-library-collections-parent',
      url: '/{collectionId:int}/:catalogId/user-management',
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasCollectionManagerPermissions() || manager.hasCourseManagerPermissions(), 'content-library-collections'),
      },
    })
    .state('license-analytics', {
      url: 'license-analytics',
      parent: 'institution-dashboard',
      views: {
        'main@mainGridContent': {
          controller: 'AnalyticsDashboardCtrl as vm',
          templateUrl: 'analytics_dashboard/templates/license-dashboard.html',
        },
        'top-header@mainGridContent': {
          template: '<div nv-l1-course-header = ""></div>',
        },
      },
      data: {
        titleKey: 'INSTITUTIONS.DASHBOARD.LICENSE_DASHBOARD.HEADER',
        headerClass: 'light-header fixed-header institution-l1-header',
        mainClass: 'advanced-settings-main analytics-dashboard',
      },
      resolve: {
        checkPermission: redirectIfUnauthorized((manager) => manager.hasOrgAdminPermissions(), 'institution-dashboard'),
      },
    })
    .state('learner-progress', {
      url: '/learner-progress',
      parent: 'course-mainGridContent',
      views: {
        'top-header': {
          templateUrl: 'learner_progress/templates/learner-progress-dashboard-header.html',
          controller: 'LightHeaderController as vm',
        },
        main: {
          controller: 'LearnerProgressDashboardCtrl as vm',
          templateUrl: 'learner_progress/templates/learner-progress-dashboard.html',
        },
      },
      data: {
        titleKey: 'LEARNER_PROGRESS.HEADER',
        mainClass: 'learner-progress-dashboard',
        headerClass: 'light-header fixed-header directory-page-header',
        titleTranslation: 'LEARNER_PROGRESS.HEADER',
      },
      resolve: {
        checkPermissions: redirectIfUnauthorized((manager) => manager.isInstructor() || manager.isReportAdmin() || manager.isTeachingAssistant(), 'course-home'),
      },
    });
}

